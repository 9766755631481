import { useContext } from "react"
import MDBox from "components/MDBox"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { Grid, Typography } from "@mui/material"
import { AuthContext } from "context"

function ManualSubscriptionDetails(): JSX.Element {
  // Context
  const { userProfileData } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)

  return (
    <MDBox pl={3} pr={3} pb={3}>
      <Typography variant="h6" gutterBottom>
        Subscription Details
      </Typography>
      <Grid container spacing={1}>
        {/* Plan Name */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Plan Name:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="end">
            Yearly
          </Typography>
        </Grid>

        {/* Status */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Status:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color={"green"} textAlign="end">
            Active
          </Typography>
        </Grid>

        {/* Expiration Date */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Renewal Date:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="end">
            {organization?.manualSubscription?.expirationDate
              ? organization?.manualSubscription?.expirationDate.toLocaleDateString()
              : userProfileData?.manualSubscription?.expirationDate
                ? userProfileData?.manualSubscription?.expirationDate.toLocaleDateString()
                : "No Expiration"}
          </Typography>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default ManualSubscriptionDetails
