import { useState, useEffect, useContext, useCallback } from "react"
import theme from "assets/theme"

// MUI
import { Card, Grid, Accordion, AccordionSummary, AccordionDetails, Stack, IconButton } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"

// Components
import EventCard from "../../../core/components/EventCard"
import Calendar from "examples/Calendar"
import { CalendarEvent } from "types/calendar/calendar-event"
import { useLocation, useNavigate } from "react-router-dom"
import { DateSelectArg, EventClickArg } from "@fullcalendar/core"
import { areSameDay, isToday, startOfDay } from "forge/core/utilities"
import { EventRepeat, Sync } from "@mui/icons-material"
import { createUseStyles } from "react-jss"
import { CalendarsContext, EventsContext } from "context"
import CreateEventDrawer from "../../../calendar/components/CreateEventDrawer"
import dayjs, { Dayjs } from "dayjs"
import { roundToNearestXDuration } from "forge/calendar/utils"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import linearGradient from "assets/theme/functions/linearGradient"
import { InvitationStatus } from "types/calendar/forge-attendee"
import useOnWindowFocus from "forge/shared/hooks/useOnWindowFocus"

const styles = createUseStyles({
  cardText: {
    fontWeight: "bold"
  },
  root: {
    ".MuiButtonBase-root": {
      padding: 0
    },
    "&:before": {
      display: "none"
    }
  }
})

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false
}

function CalendarView(): JSX.Element {
  const classes = styles()
  const navigate = useNavigate()
  const { state } = useLocation()

  // Context
  const { events, fetchEvents } = useContext(EventsContext)
  const { calendars } = useContext(CalendarsContext)

  // State
  const [firstLoadDate, setFirstLoadDate] = useState<Date>(new Date())
  const [fetchingEvents, setFetchingEvents] = useState<boolean>(false)
  const [firstEventOfDayTime, setFirstEventOfDayTime] = useState<Date>()
  const [selectedDay, setSelectedDay] = useState(startOfDay(new Date()))
  const [schedulerData, setSchedulerData] = useState([])
  const [agendaData, setAgendaData] = useState<{ [key: string]: any }>({})
  const [openCreateEventDrawer, setOpenCreateEventDrawer] = useState(false)

  const now = new Date()
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(roundToNearestXDuration(now, 5 * 60 * 1000)))
  now.setMinutes(now.getMinutes() + 30)
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(roundToNearestXDuration(now, 5 * 60 * 1000)))

  const handleOpenDrawer = () => setOpenCreateEventDrawer(true)
  const handleCloseDrawer = () => setOpenCreateEventDrawer(false)

  const parseEvents = (info: CalendarEvent[]) => {
    let firstEventOfDayTimeSet = false
    const updatedInfo = info.map(event => {
      if (!firstEventOfDayTimeSet && !event.isAllDay && event.startDate && areSameDay(new Date(), event.startDate?.toDate())) {
        const newDate = new Date(event.startDate?.toDate()) // Create a new Date object to avoid mutating the original date
        newDate.setMinutes(newDate.getMinutes() - 30)
        setFirstEventOfDayTime(newDate)
        firstEventOfDayTimeSet = true
      }

      return {
        id: event.eventId,
        title: event.name,
        start: event.startDate?.toDate(),
        end: event.endDate?.toDate() ?? event.startDate?.toDate(),
        className: "info",
        color: event.color(),
        event: event,
        ...event
      }
    })

    if (!firstEventOfDayTimeSet) {
      const newDate = new Date()
      newDate.setMinutes(newDate.getMinutes() - 30)
      setFirstEventOfDayTime(newDate)
    }

    return updatedInfo
  }

  const getAgendaEvents = useCallback((info: any[]) => {
    const today = new Date()
    today.setHours(0, 0, 0)
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 7)

    const filteredEvents = info.filter(event => {
      const eventDate = new Date(event.start)
      return eventDate >= today && eventDate <= endDate
    })

    return groupEventsByDay(filteredEvents)
  }, [])

  // Function to group events by day
  function groupEventsByDay(events: any[]) {
    const groupedEvents: { [key: string]: any } = {}
    events.forEach(event => {
      const startDate = new Date(event.start)

      // Date Key
      // const dateKey = startDate.toISOString().split("T")[0]; // Using date as key
      let year = startDate.getFullYear()
      let month = (startDate.getMonth() + 1).toString().padStart(2, "0")
      let day = startDate.getDate().toString().padStart(2, "0")
      const dateKey = `${year}-${month}-${day}` // Using date as key

      if (!groupedEvents[dateKey]) {
        groupedEvents[dateKey] = []
      }
      groupedEvents[dateKey].push(event)
    })
    return groupedEvents
  }

  const handleEventClick = (eventId: string) => {
    navigate(`/calendar/events/${eventId}`)
  }

  const onAddEvent = () => {
    if (calendars.length === 0) {
      navigate(`/settings#calendars`)
      return
    }

    handleOpenDrawer()
  }

  const onAddNewCalendar = () => {
    navigate(`/settings#calendars`)
  }

  const pastEvents = () => {
    const now = new Date()
    const pastEvents = agendaData[now.toISOString().split("T")[0]]?.filter((e: any) => e.end < now)

    if (!pastEvents || pastEvents.length === 0) {
      return <></>
    }

    return (
      <Accordion style={{ boxShadow: "none" }} className={classes.root}>
        <AccordionSummary expandIcon={<EventRepeat />} aria-controls="panel1-content" id="panel1-header">
          <MDTypography variant="caption" fontWeight="bold" fontSize="medium" textTransform="capitalize" style={{ color: "#0000008f" }}>
            Today's past events
          </MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          {pastEvents.map((e: any) => (
            <EventCard event={e.event} />
          ))}
        </AccordionDetails>
      </Accordion>
    )
  }

  const updateBackgroundByStatus = (event: any) => {
    const status = event?.properties?.userAttendee?.status

    const textColor = status === InvitationStatus.none ? "black" : theme.palette.getContrastText(event.color)

    const color =
      status === InvitationStatus.none
        ? linearGradient(
            `#f8f9fa 10%,
      lightgray 10%,
      lightgray 20%,
      #f8f9fa 20%,
      #f8f9fa 30%,
      lightgray 30%,
      lightgray 40%,
      #f8f9fa 40%,
      #f8f9fa 50%,
      lightgray 50%,
      lightgray 60%,
      #f8f9fa 60%,
      #f8f9fa 70%,
      lightgray 70%,
      lightgray 80%,
      #f8f9fa 80%,
      #f8f9fa 90%,
      lightgray 90%`,
            "lightgray 100%",
            -45
          )
        : event.color

    return {
      ...event,
      textColor,
      color
    }
  }

  useEffect(() => {
    const parsedEvents = parseEvents(events)
    //* Removing duplicates with same name, date and calendar
    const filteredEvents = parsedEvents
      .filter(
        (item: any, index: number, self: any) =>
          index ===
          self.findIndex((obj: any) => {
            const objDate = new Date(obj.start)
            const itemDate = new Date(item.start)
            return (
              obj.name === item.name &&
              obj.calendarId === item.calendarId &&
              objDate.getDate() === itemDate.getDate() &&
              objDate.getMonth() === itemDate.getMonth() &&
              objDate.getFullYear() === itemDate.getFullYear()
            )
          })
      )
      .map(item => updateBackgroundByStatus(item))

    setSchedulerData(filteredEvents)
    setAgendaData(getAgendaEvents(filteredEvents))

    if (state?.deletedCalendarEvent || state?.movedCalendarEvent) {
      const eventChangeDate = new Date(state.event)
      setSelectedDay(eventChangeDate)
    }
  }, [events, state, getAgendaEvents, firstLoadDate])

  useOnWindowFocus({
    onFocusAction: () => {
      if (!(state?.deletedCalendarEvent || state?.movedCalendarEvent)) {
        const now = new Date()
        if (!areSameDay(now, firstLoadDate)) {
          setFirstLoadDate(now)
          setSelectedDay(now)
        }
      }
    }
  })

  return (
    <Card>
      <CreateEventDrawer
        openDrawer={openCreateEventDrawer}
        handleCloseDrawer={handleCloseDrawer}
        initialStartDate={startDate}
        initialEndDate={endDate}
      />
      <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <MDTypography variant="h5" fontWeight="medium">
            Calendar
          </MDTypography>
          <IconButton
            onClick={async () => {
              if (!fetchingEvents) {
                setFetchingEvents(true)
                await fetchEvents()
                setFetchingEvents(false)
              }
            }}
            style={{ padding: fetchingEvents ? "0px" : null }}
          >
            {fetchingEvents ? <ForgeLoading loading loadingType="small" /> : <Sync />}
          </IconButton>
        </Stack>
        {/* {ability.can("create", "categories") && ( */}
        <Stack direction="row" spacing={0.5} alignItems="center">
          <MDButton variant="gradient" color="dark" size="small" type="submit" style={{ textTransform: "none", height: "40px" }} onClick={onAddEvent}>
            + Add Event
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            type="submit"
            style={{ textTransform: "none", height: "40px" }}
            onClick={onAddNewCalendar}
          >
            + Add Calendar
          </MDButton>
        </Stack>
        {/* )} */}
      </MDBox>
      <MDBox py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox ml={3}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} pl={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Next 7 days
                </MDTypography>
              </MDBox>
              {pastEvents()}
              {Object.entries(agendaData).map(([dayKey, events]) => {
                let date = new Date(dayKey)

                if (isToday(selectedDay) && selectedDay.toISOString().split("T")[0] === dayKey) {
                  events = events?.filter((e: any) => e.end > new Date())
                }

                return events.length > 0 ? (
                  <MDBox pl={1}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" fontWeight="bold" fontSize="medium" textTransform="capitalize" style={{ color: "#0000008f" }}>
                        {date.toLocaleDateString("en-US", {
                          weekday: "long",
                          month: "short",
                          day: "2-digit",
                          timeZone: "UTC"
                        })}
                      </MDTypography>
                    </MDBox>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{ listStyle: "none" }}>
                      {events.map((event: any, index: number) => (
                        <EventCard event={event.event} key={index} />
                      ))}
                    </MDBox>
                  </MDBox>
                ) : (
                  <></>
                )
              })}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            {firstEventOfDayTime && (
              <Calendar
                headerToolbar={false}
                initialView="timeGridWeek"
                initialDate={selectedDay}
                scrollTime={firstEventOfDayTime?.toLocaleTimeString("en-US", timeOptions)}
                displayEventTime={false}
                events={schedulerData.map(event => {
                  return {
                    id: event.id,
                    title: event.title,
                    start: event.event?.isAllDay ? event.start.toISOString().split("T")[0] : event.start.toISOString(),
                    end: event.event?.isAllDay ? event.end.toISOString().split("T")[0] : event.end.toISOString(),
                    className: classes.cardText,
                    color: event.color,
                    displayEventTime: false,
                    textColor: event.textColor
                  }
                })}
                selectable
                editable
                select={(arg: DateSelectArg) => {
                  setStartDate(dayjs(arg.start))
                  setEndDate(dayjs(arg.end))
                }}
                eventClick={(event: EventClickArg) => {
                  handleEventClick(event.event.id)
                }}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of CommitmentCard
CalendarView.defaultProps = {
  events: []
}

export default CalendarView
