import { DocumentReference, DocumentSnapshot, Timestamp } from "firebase/firestore"
import { dateFromMap } from "forge/core/utils/schema-parsing"
import { Crm } from "types/pipeline/crm"

export class ForgeOrganization {
  ref?: DocumentReference
  parentOrganizationRef?: DocumentReference
  subOrganizations?: DocumentReference[]
  sealdGroupId: string
  domain: string
  name: string
  legalName?: string
  url?: string
  address?: string
  linkedInUrl?: string
  createdAt?: Date
  updatedAt?: Date
  pipelineConfig?: {
    crmId: string
    integration: Crm
    isEnabled: boolean
    enableDate: Date
    lastSync: Date
    pipelineLoading: boolean
    loadingProcess?: number | null
  }

  // Stripe
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  stripeData?: {
    customer?: any
    subscription?: any
  }

  // Manual Subscription
  manualSubscription?: {
    enabled?: boolean
    expirationDate?: Date
  }

  // Deletion
  preDeleted?: boolean

  // eslint-disable-next-line
  constructor({
    ref,
    parentOrganizationRef,
    subOrganizations,
    sealdGroupId,
    domain,
    name,
    legalName,
    url,
    address,
    linkedInUrl,
    createdAt,
    updatedAt,
    pipelineConfig,
    stripeCustomerId,
    stripeSubscriptionId,
    stripeData,
    manualSubscription,
    preDeleted
  }: {
    ref?: DocumentReference
    parentOrganizationRef?: DocumentReference
    subOrganizations?: DocumentReference[]
    sealdGroupId?: string
    domain: string
    name: string
    legalName?: string
    url?: string
    address?: string
    linkedInUrl?: string
    createdAt?: Date
    updatedAt?: Date
    pipelineConfig?: {
      crmId: string
      integration: Crm
      isEnabled: boolean
      enableDate: Date
      lastSync: Date
      pipelineLoading: boolean
      loadingProcess?: number | null
    }
    stripeCustomerId?: string
    stripeSubscriptionId?: string
    stripeData?: {
      customer?: any
      subscription?: any
    }
    manualSubscription?: {
      enabled?: boolean
      expirationDate?: Date
    }
    preDeleted?: boolean
  }) {
    this.ref = ref
    this.parentOrganizationRef = parentOrganizationRef
    this.subOrganizations = subOrganizations
    this.sealdGroupId = sealdGroupId
    this.domain = domain
    this.name = name
    this.legalName = legalName
    this.url = url
    this.address = address
    this.linkedInUrl = linkedInUrl
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.pipelineConfig = pipelineConfig
    this.stripeCustomerId = stripeCustomerId
    this.stripeSubscriptionId = stripeSubscriptionId
    this.stripeData = stripeData
    this.manualSubscription = manualSubscription
    this.preDeleted = preDeleted ?? false
  }

  toMap(toFirestore = true): any {
    const map = {
      ref: toFirestore ? this.ref : this.ref?.path,
      parentOrganizationRef: toFirestore ? this.parentOrganizationRef : this.parentOrganizationRef?.path,
      subOrganizations: this.subOrganizations?.map(e => (toFirestore ? e : e?.path)),
      sealdGroupId: this.sealdGroupId,
      domain: this.domain,
      name: this.name,
      legalName: this.legalName,
      url: this.url,
      address: this.address,
      linkedInUrl: this.linkedInUrl,
      createdAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.createdAt?.valueOf(),
      updatedAt: toFirestore ? this.updatedAt ?? Timestamp.now() : this.updatedAt?.valueOf(),
      pipelineConfig: this.pipelineConfig,
      stripeCustomerId: this.stripeCustomerId,
      stripeSubscriptionId: this.stripeSubscriptionId,
      stripeData: this.stripeData,
      manualSubscription: {
        enabled: this.manualSubscription?.enabled,
        expirationDate: this.manualSubscription?.expirationDate
          ? toFirestore
            ? Timestamp.fromDate(this.manualSubscription.expirationDate)
            : this.manualSubscription.expirationDate
          : undefined
      },
      preDeleted: this.preDeleted
    }

    return map
  }

  static fromFirestore(doc: DocumentSnapshot): ForgeOrganization {
    const map = doc.data()
    return new ForgeOrganization({
      ref: doc.ref,
      parentOrganizationRef: map.parentOrganizationRef,
      subOrganizations: map.subOrganizations,
      sealdGroupId: map.sealdGroupId,
      domain: map.domain,
      name: map.name,
      legalName: map.legalName,
      url: map.url,
      address: map.address,
      linkedInUrl: map.linkedInUrl,
      createdAt: dateFromMap(map.createdAt) ?? new Date(),
      updatedAt: dateFromMap(map.updatedAt) ?? new Date(),
      pipelineConfig: map.pipelineConfig,
      stripeCustomerId: map.stripeCustomerId,
      stripeSubscriptionId: map.stripeSubscriptionId,
      stripeData: map.stripeData,
      manualSubscription: {
        enabled: map.manualSubscription?.enabled,
        expirationDate: dateFromMap(map.manualSubscription?.expirationDate)
      },
      preDeleted: map.preDeleted
    })
  }

  get hasAnActiveSubscription(): boolean {
    return (
      !!this.stripeSubscriptionId ||
      (!!this.manualSubscription?.enabled && (!this.manualSubscription?.expirationDate || new Date() < this.manualSubscription?.expirationDate))
    )
  }
}
