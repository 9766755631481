import React, { useEffect } from "react"

interface HookProps {
  onFocusAction?: () => void
  onBlurAction?: () => void
}

const useOnWindowFocus: React.FC<HookProps> = ({ onFocusAction, onBlurAction }) => {
  // User has switched back to the tab
  const onFocus = () => {
    if (onFocusAction) {
      onFocusAction()
    }
  }

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    if (onBlurAction) onBlurAction()
  }

  useEffect(() => {
    window.addEventListener("focus", onFocus)
    window.addEventListener("blur", onBlur)
    // Calls onFocus when the window first loads
    onFocus()
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus)
      window.removeEventListener("blur", onBlur)
    }
  }, [])

  return <div></div>
}

export default useOnWindowFocus
