import { useContext, useState } from "react"
import { AuthContext } from "context"

// MUI
import { Divider, Drawer, FormControl, useMediaQuery } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"

// Images
import theme from "assets/theme"
import ContactsApi from "../../services/api"
import SelectContact from "forge/core/components/SelectContact"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import MDSnackbar from "components/MDSnackbar"

interface MergeContactsDrawerProps {
  openDrawer: boolean
  handleCloseDrawer: () => void
  contact: any
  onNotify: (notification: SnackbarNotification) => void
}
function MergeContactsDrawer({ openDrawer, handleCloseDrawer, contact, onNotify }: MergeContactsDrawerProps) {
  // UI
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { getCurrentUser } = useContext(AuthContext)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [contactsToMerge, setContactsToMerge] = useState<any[]>([])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)

    try {
      if (contactsToMerge.length > 0) {
        const result = await contactsApi.mergeContact(contact, contactsToMerge)
        onNotify({
          color: result ? "info" : "error",
          title: result ? "Contact Merge in Progress" : "Contact Merge Failed",
          content: result
            ? "We're merging your selected contacts into one. You'll be notified once the process is complete."
            : "An error occurred while merging your contacts. Please try again or contact support for assistance."
        })
      }
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
    handleCloseDrawer()
  }

  const onContactSelected = (contact: any) => {
    setContactsToMerge([...contactsToMerge, contact])
  }

  const onContactUnselected = (contact: any) => {
    let toMerge = [...contactsToMerge]
    toMerge = toMerge.filter(e => e.ref?.id !== contact.ref?.id)
    setContactsToMerge(toMerge)
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            maxHeight: "96%",
            width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
          }
        }}
      >
        <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
          <ForgeLoading loading={loading} customHeight="100%" />

          {/* Header */}
          <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3} style={{ background: "#F7F8FB" }}>
            <MDBox>
              <MDTypography variant="h5">Merge Contact</MDTypography>
            </MDBox>
            <MDButton variant="gradient" color="info" type="submit">
              Merge
            </MDButton>
          </MDBox>
          <MDBox pt={1} style={{ background: "#F7F8FB" }} />
          <Divider style={{ background: "#F7F8FB", margin: 0, opacity: 1 }} />
          <MDBox pt={2} style={{ background: "#F7F8FB" }} />

          {/* Body */}
          <div style={{ background: "#F7F8FB" }}>
            <SelectContact
              onContactSelected={onContactSelected}
              onContactUnselected={onContactUnselected}
              filteredIds={[contact?.ref?.id]}
              recommendedIds={[]}
              isOrganization={contact.isOrganizationContact}
              enforceContactTypeSeparation={true}
            />
          </div>
        </FormControl>
      </Drawer>
    </>
  )
}

// Setting default values for the props of MergeContactsDrawer
MergeContactsDrawer.defaultProps = {
  handleCloseDrawer: null
}

export default MergeContactsDrawer
