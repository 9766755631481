import { Avatar, AvatarGroup, Box, Card, Tooltip } from "@mui/material"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import DataTable from "examples/Tables/DataTable"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import TeamDrawer from "../TeamDrawer"
import CreateTeamDialog from "../CreateTeamDialog"
import { TeamsContext } from "../../services/TeamsContext"
import { ForgeOrganizationTeam } from "forge/organization/types/team"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { ForgeMemberAvatar } from "forge/core/components/ForgeAvatar"
import { Can } from "forge/organization/rbac/CanContext"
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip"
import { HexagonAvatar } from "forge/core/components/HexagonAvatar"
import { MembersContext } from "forge/organization/members/services/MembersContext"

const styles = createUseStyles({
  avatar: {
    color: "white",
    backgroundColor: "black",
    fontSize: "1rem"
  }
})

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "2-digit"
}

function OrganizationTeams() {
  // UI
  const classes = styles()

  // Context
  const { members } = useContext(MembersContext)
  const { teams } = useContext(TeamsContext)

  // State
  const [tableData, setTableData] = useState([])
  const [selectedTeam, setSelectedTeam] = useState<ForgeOrganizationTeam>()
  const [openDialog, setOpenDialog] = useState(false)
  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const handleOpenDrawer = () => setOpenDrawer(true)
  const handleCloseDrawer = () => setOpenDrawer(false)

  // Sorting
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([])
  const handleSort = (columnId: string) => {
    setSorting(prevSorting => {
      const isCurrentlySorted = prevSorting.find(s => s.id === columnId)
      if (isCurrentlySorted) {
        // Toggle between ascending, descending, and no sort
        if (isCurrentlySorted.desc) {
          return prevSorting.filter(s => s.id !== columnId) // Remove sort
        } else {
          return prevSorting.map(s => (s.id === columnId ? { ...s, desc: true } : s)) // Descending
        }
      } else {
        // Default to ascending sort
        return [{ id: columnId, desc: false }]
      }
    })
  }

  useEffect(() => {
    const tmp = getRows(teams)
    setTableData(tmp)
    console.log("info", tmp)
  }, [teams])

  const getRows = (info: ForgeOrganizationTeam[]) => {
    console.log("info", info)
    let updatedInfo = info.map((row: ForgeOrganizationTeam) => {
      console.log("inforow", row)
      return {
        type: "teams",
        id: row.ref.id,
        name: row.name,
        description: row.description,
        createdAt: row.createdAt.toLocaleDateString("en-US", options),
        team: row
      }
    })
    return updatedInfo
  }

  const dataTableData = {
    columns: [
      {
        Header: "name",
        width: "20%",
        Cell: (info: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <Tooltip title={info.cell.row.original.name}>
                <HexagonAvatar alt={info.cell.row.original.name} src={info.cell.row.original.teamLogo}>
                  {info.cell.row.original.name[0]}
                </HexagonAvatar>
              </Tooltip>
              <Box sx={{ ml: 2 }}>{info.cell.row.original.name}</Box>
            </MDBox>
          )
        }
      },
      {
        Header: "members",
        width: "20%",
        Cell: (info: any) => {
          return (
            <AvatarGroup max={4} classes={{ avatar: classes.avatar }}>
              {info.cell.row.original.team.members.map((member: ForgeOrganizationMember) => {
                return <ForgeMemberAvatar member={member} />
              })}
            </AvatarGroup>
          )
        }
      },
      {
        Header: "Created By",
        width: "20%",
        Cell: (info: any) => {
          if (info.cell.row.original.team.createdBy) {
            let createdBy: ForgeOrganizationMember = info.cell.row.original.team.createdBy

            return (
              <MDBox display="flex" alignItems="center">
                <ForgeMemberAvatar member={createdBy} />
                <Box sx={{ ml: 2 }}>{createdBy.name}</Box>
              </MDBox>
            )
          } else {
            return <div></div>
          }
        }
      },
      { Header: "Created At", accessor: "createdAt", width: "15%" }
    ],
    rows: tableData,
    sorting,
    onSort: handleSort
  }

  return (
    <MDBox mb={3}>
      <CreateTeamDialog open={openDialog} handleClose={handleCloseDialog} />
      <TeamDrawer openDrawer={openDrawer} handleClose={handleCloseDrawer} team={selectedTeam} />
      <Card id="teams">
        <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            Teams
          </MDTypography>
          <Can I="create" an="teams" passThrough>
            {(allowed: boolean) => (
              <DisabledTooltip
                allowed={allowed && members.length > 1}
                message={members.length < 2 ? "Please add members to your organization before creating a team." : undefined}
              >
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  disabled={!allowed || members.length < 2}
                  onClick={handleOpenDialog}
                >
                  + Add Team
                </MDButton>
              </DisabledTooltip>
            )}
          </Can>
        </MDBox>
        <DataTable
          tableId="teams"
          table={dataTableData}
          canSearch={true}
          onRowClick={(row, index) => {
            setSelectedTeam(row.original.team)
            handleOpenDrawer()
          }}
        />
      </Card>
    </MDBox>
  )
}

export default OrganizationTeams
