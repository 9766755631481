import { DocumentReference, Timestamp, doc, getDoc } from "firebase/firestore"
import { ForgeEvent } from "./forge-event"
import { firestoreDb } from "firebase.init"
import { EncryptionConfig } from "./encryption-config"
import { EncryptionResult, ForgeEncryption } from "forge/core/services/encryption"
import { dateFromMap, documentReferenceFromMap } from "forge/core/utils/schema-parsing"

export class Commitment extends ForgeEvent {
  contactRefs: DocumentReference[]
  enableReminder: boolean
  reminderDays: number
  completed: boolean
  completionDate?: Date
  completedByDeadline: boolean
  timesRescheduled: number
  editedTask: boolean
  isArchived: boolean
  frequency?: string
  dailyFrequencyDays?: boolean[]
  childCommitments?: Commitment[]
  isChildCommitment: boolean
  childIndex?: number
  isOrganizationCommitment: boolean
  createdBy: DocumentReference
  encryptedBy: DocumentReference
  createdAt: Date
  updatedAt: Date

  constructor({
    ref,
    contacts,
    contactRefs,
    name,
    startDate,
    endDate,
    enableReminder,
    reminderDays,
    completed = false,
    completionDate,
    completedByDeadline = false,
    timesRescheduled = 0,
    editedTask = false,
    isArchived = false,
    frequency,
    dailyFrequencyDays,
    childCommitments,
    isChildCommitment = false,
    childIndex,
    opportunityId,
    isProcessing = false,
    isOrganizationCommitment = false,
    createdBy,
    encryptedBy,
    createdAt,
    updatedAt
  }: {
    ref?: DocumentReference
    contacts: any[]
    contactRefs?: DocumentReference[]
    name: string
    startDate: Date
    endDate?: Date
    enableReminder: boolean
    reminderDays: number
    completed?: boolean
    completionDate?: Date
    completedByDeadline?: boolean
    timesRescheduled?: number
    editedTask?: boolean
    isArchived?: boolean
    frequency?: string
    dailyFrequencyDays?: boolean[]
    childCommitments?: Commitment[]
    isChildCommitment?: boolean
    childIndex?: number
    opportunityId?: string
    isProcessing?: boolean
    isOrganizationCommitment?: boolean
    createdBy: DocumentReference
    encryptedBy: DocumentReference
    createdAt?: Date
    updatedAt?: Date
  }) {
    super(
      name,
      startDate ? Timestamp.fromDate(startDate) : Timestamp.now(),
      isProcessing,
      ref,
      endDate ? Timestamp.fromDate(endDate) : Timestamp.now(),
      contacts,
      opportunityId
    )
    this.contactRefs = contactRefs
    this.enableReminder = enableReminder
    this.reminderDays = reminderDays
    this.completed = completed
    this.completionDate = completionDate
    this.completedByDeadline = completedByDeadline
    this.timesRescheduled = timesRescheduled
    this.editedTask = editedTask
    this.isArchived = isArchived
    this.frequency = frequency
    this.dailyFrequencyDays = dailyFrequencyDays
    this.childCommitments = childCommitments
    this.isChildCommitment = isChildCommitment
    this.childIndex = childIndex
    this.isOrganizationCommitment = isOrganizationCommitment
    this.createdBy = createdBy
    this.encryptedBy = encryptedBy
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  copyWith({
    ref,
    contacts,
    contactRefs,
    name,
    startDate,
    endDate,
    enableReminder,
    reminderDays,
    completed,
    completionDate,
    completedByDeadline,
    timesRescheduled,
    editedTask,
    isArchived,
    frequency,
    dailyFrequencyDays,
    childCommitments,
    isChildCommitment,
    childIndex,
    opportunityId,
    isProcessing,
    isOrganizationCommitment,
    createdBy,
    encryptedBy,
    createdAt,
    updatedAt
  }: {
    ref?: DocumentReference
    contacts?: any[]
    contactRefs?: DocumentReference[]
    name?: string
    startDate?: Date
    endDate?: Date
    enableReminder?: boolean
    reminderDays?: number
    completed?: boolean
    completionDate?: Date
    completedByDeadline?: boolean
    timesRescheduled?: number
    editedTask?: boolean
    isArchived?: boolean
    frequency?: string
    dailyFrequencyDays?: boolean[]
    childCommitments?: Commitment[]
    isChildCommitment?: boolean
    childIndex?: number
    opportunityId?: string
    isProcessing?: boolean
    isOrganizationCommitment?: boolean
    createdBy?: DocumentReference
    encryptedBy?: DocumentReference
    createdAt?: Date
    updatedAt?: Date
  }) {
    return new Commitment({
      ref: ref ?? this.ref,
      contacts: contacts ?? this.contacts,
      contactRefs: contactRefs ?? this.contactRefs,
      name: name ?? this.name,
      startDate: startDate ?? this.startDate.toDate(),
      endDate: endDate ?? this.endDate.toDate(),
      enableReminder: enableReminder ?? this.enableReminder,
      reminderDays: reminderDays ?? this.reminderDays,
      completed: completed ?? this.completed,
      completionDate: completionDate ?? this.completionDate,
      completedByDeadline: completedByDeadline ?? this.completedByDeadline,
      timesRescheduled: timesRescheduled ?? this.timesRescheduled,
      editedTask: editedTask ?? this.editedTask,
      isArchived: isArchived ?? this.isArchived,
      frequency: frequency ?? this.frequency,
      dailyFrequencyDays: dailyFrequencyDays ?? this.dailyFrequencyDays,
      childCommitments: childCommitments ?? this.childCommitments,
      isChildCommitment: isChildCommitment ?? this.isChildCommitment,
      childIndex: childIndex ?? this.childIndex,
      opportunityId: opportunityId ?? this.opportunityId,
      isProcessing: isProcessing ?? this.isProcessing,
      isOrganizationCommitment: isOrganizationCommitment ?? this.isOrganizationCommitment,
      createdBy: createdBy ?? this.createdBy,
      encryptedBy: encryptedBy ?? this.encryptedBy,
      createdAt: createdAt ?? this.createdAt,
      updatedAt: updatedAt ?? this.updatedAt
    })
  }

  async toMap({
    toFirestore = false,
    encryptionConfig
  }: {
    toFirestore?: boolean
    encryptionConfig?: EncryptionConfig
  } = {}): Promise<any> {
    const isEncrypted = encryptionConfig && encryptionConfig.encrypted
    const encryptionResultName: EncryptionResult = isEncrypted
      ? await encryptionConfig.service.encrypt(this.name, encryptionConfig.organization?.sealdGroupId, this.ref?.path)
      : {
          result: this.name,
          encrypter: "seald",
          encrypted: false
        }

    const children = await Promise.all(
      (this.childCommitments ?? []).map(childCommitment => {
        delete childCommitment.childCommitments
        childCommitment.name = encryptionResultName.result
        try {
          encryptionConfig.skipEncryption = true
          return childCommitment.toMap({
            toFirestore,
            encryptionConfig: encryptionConfig
          })
        } catch (error) {
          console.warn(error)
        }
      })
    )

    const commitment: any = {
      ref: toFirestore ? this.ref : this.ref?.path,
      contacts: this.contactRefs?.map(x => (toFirestore ? x : x?.path)),
      contactRefs: this.contactRefs?.map(x => (toFirestore ? x : x?.path)),
      name: encryptionResultName.result,
      startDate: toFirestore ? this.startDate : this.startDate.toMillis(),
      endDate: toFirestore ? this.endDate : this.endDate?.toMillis(),
      enableReminder: this.enableReminder,
      reminderDays: this.reminderDays,
      completed: this.completed,
      completionDate: toFirestore ? this.updatedAt ?? null : this.completionDate?.valueOf(),
      completedByDeadline: this.completedByDeadline,
      timesRescheduled: this.timesRescheduled,
      editedTask: this.editedTask,
      isArchived: this.isArchived,
      frequency: this.frequency ?? null,
      dailyFrequencyDays: this.dailyFrequencyDays,
      childCommitments: children,
      isChildCommitment: this.isChildCommitment,
      childIndex: this.childIndex ?? null,
      encrypted: encryptionResultName.encrypted,
      encrypter: encryptionResultName.encrypter,
      opportunityId: this.opportunityId,
      isProcessing: this.isProcessing,
      isOrganizationCommitment: this.isOrganizationCommitment,
      createdBy: toFirestore ? this.createdBy : this.createdBy?.path,
      encryptedBy: toFirestore ? this.encryptedBy : this.encryptedBy?.path,
      createdAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.createdAt?.valueOf(),
      updatedAt: toFirestore ? this.updatedAt ?? Timestamp.now() : this.updatedAt?.valueOf()
    }

    if (!commitment["opportunityId"]) {
      delete commitment["opportunityId"]
    }

    return commitment
  }

  static async fromMap(map: any, encryptionService: ForgeEncryption): Promise<Commitment> {
    const encryptedBy = documentReferenceFromMap(map.encryptedBy)

    const name =
      map.encrypted ?? false
        ? await encryptionService.decrypt({
            data: map.name,
            encrypter: map.encrypter
          })
        : map.name

    return new Commitment({
      ref: documentReferenceFromMap(map.ref),
      contacts: [],
      contactRefs: map.contactRefs
        ? map.contactRefs.map((x: string | DocumentReference) => {
            if (x instanceof DocumentReference) {
              return x
            } else if (typeof x === "string") {
              return doc(firestoreDb, x)
            }
            return null
          })
        : null,
      name: name,
      startDate: dateFromMap(map.startDate),
      endDate: dateFromMap(map.endDate),
      enableReminder: map.enableReminder ?? false,
      reminderDays: map.reminderDays ?? 0,
      completed: map.completed ?? false,
      completionDate: dateFromMap(map.completionDate),
      completedByDeadline: map.completedByDeadline ?? false,
      timesRescheduled: map.timesRescheduled ?? 0,
      editedTask: map.editedTask ?? false,
      isArchived: map.isArchived ?? false,
      frequency: map.frequency,
      dailyFrequencyDays: map.dailyFrequencyDays,
      isChildCommitment: map.isChildCommitment ?? false,
      childIndex: map.childIndex,
      opportunityId: map.opportunityId,
      isProcessing: map.isProcessing ?? false,
      isOrganizationCommitment: map.isOrganizationCommitment ?? false,
      createdBy: documentReferenceFromMap(map.createdBy),
      encryptedBy: encryptedBy,
      createdAt: dateFromMap(map.createdAt),
      updatedAt: dateFromMap(map.updatedAt)
    })
  }
}
