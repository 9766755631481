import { Box, Card, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import DataTable from "examples/Tables/DataTable"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import MemberDrawer from "../MemberDrawer"
import { MembersContext } from "../../services/MembersContext"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { capitalize } from "lodash"
import InviteMemberDialog from "../InviteMemberDialog"
import { ForgeMemberAvatar } from "forge/core/components/ForgeAvatar"
import { Can } from "forge/organization/rbac/CanContext"
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import { AuthContext } from "forge/auth/services/AuthContext"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import BillingDialog from "forge/settings/components/SettingsBilling/BillingDialog"

const styles = createUseStyles({
  avatar: {
    color: "black",
    backgroundColor: "#F7F8FB",
    fontSize: "1rem"
  }
})

function OrganizationMembers() {
  // UI
  const classes = styles()

  // Context
  const { userProfileData } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)
  const { members, acceptMember, rejectMember } = useContext(MembersContext)

  // State
  const [openBillingDialog, setOpenBillingDialog] = useState(false)
  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [selectedMember, setSelectedMember] = useState<ForgeOrganizationMember>()
  const [openDialog, setOpenDialog] = useState(false)
  const handleOpenDialog = () => {
    // Verify Active Subscription
    if (!verifyActiveSubscription()) return

    return setOpenDialog(true)
  }
  const handleCloseDialog = () => setOpenDialog(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const handleOpenDrawer = () => setOpenDrawer(true)
  const handleCloseDrawer = () => setOpenDrawer(false)
  const [loadingId, setLoadingId] = useState<string>()

  // Sorting
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([])
  const handleSort = (columnId: string) => {
    setSorting(prevSorting => {
      const isCurrentlySorted = prevSorting.find(s => s.id === columnId)
      if (isCurrentlySorted) {
        // Toggle between ascending, descending, and no sort
        if (isCurrentlySorted.desc) {
          return prevSorting.filter(s => s.id !== columnId) // Remove sort
        } else {
          return prevSorting.map(s => (s.id === columnId ? { ...s, desc: true } : s)) // Descending
        }
      } else {
        // Default to ascending sort
        return [{ id: columnId, desc: false }]
      }
    })
  }

  useEffect(() => {
    setTableData(getRows(members))
  }, [members])

  // Subscription verification utility function
  const verifyActiveSubscription = () => {
    if (!organization?.hasAnActiveSubscription && !userProfileData?.hasAnActiveSubscription) {
      setOpenBillingDialog(true) // Callback to open billing dialog
      return false // Return false if the subscription is not active
    }
    return true // Return true if the subscription is active
  }

  const handleAccept = async (row: any, event: any) => {
    event.preventDefault()
    event.stopPropagation()

    // Verify Active Subscription
    if (!verifyActiveSubscription()) return

    const member = row.original.member
    setLoadingId(member.ref.id)
    await acceptMember(member.ref.id, member)
    setLoadingId(undefined)
  }

  const handleReject = async (row: any, event: any) => {
    event.preventDefault()
    event.stopPropagation()

    const member = row.original.member
    setLoadingId(member.ref.id)
    await rejectMember(member.ref.id)
    setLoadingId(undefined)
  }

  const getRows = (info: ForgeOrganizationMember[]) => {
    let updatedInfo = info.map(row => {
      return {
        type: "contacts",
        id: row.ref.id,
        name: row.name,
        firstName: row.firstName,
        lastName: row.lastName,
        profilePicture: row.profilePictureUrl,
        role: capitalize(row.role),
        contacts: 0,
        added: row.joinedAtLocalized,
        status: row.status,
        member: row
      }
    })
    return updatedInfo
  }

  const dataTableData = {
    columns: [
      {
        Header: "name",
        width: "20%",
        Cell: (info: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <ForgeMemberAvatar member={info.cell.row.original.member} />
              <Box sx={{ ml: 2 }}>{info.cell.row.original.name}</Box>
            </MDBox>
          )
        }
      },
      // {
      //     Header: "team",
      //     width: "20%",
      //     Cell: (info: any) => {
      //         return (
      //             <AvatarGroup max={4} classes={{ avatar: classes.avatar }}>
      //                 {info.cell.row.original.member.teams?.map((team: ForgeOrganizationTeam) =>
      //                     <Avatar alt={team.name}>
      //                         {team.name[0]}
      //                     </Avatar>
      //                 )}
      //             </AvatarGroup>
      //         );
      //     },
      // },
      {
        Header: "role",
        accessor: "role",
        width: "20%"
      },
      { Header: "contacts", accessor: "contacts", width: "15%" },
      { Header: "added", accessor: "added", width: "15%" },
      {
        Header: "status",
        accessor: "status",
        width: "15%",
        Cell: (info: any) => {
          const status = info.cell.row.original.status
          if (info.cell.row.original.id === loadingId) {
            return <ForgeLoading loading loadingType="small" />
          }

          if (status === "pendingActivation") {
            return (
              <Stack direction="column" spacing={1}>
                <MDButton size="small" onClick={e => handleAccept(info.cell.row, e)} color="info">
                  Accept
                </MDButton>
                <MDButton size="small" variant="outlined" onClick={e => handleReject(info.cell.row, e)} color={"info"}>
                  Reject
                </MDButton>
              </Stack>
            )
          }

          return (
            <MDBox display="flex" alignItems="center">
              {capitalize(info.cell.row.original.status)}
            </MDBox>
          )
        }
      }
    ],
    rows: tableData,
    sorting,
    onSort: handleSort
  }

  return (
    <MDBox mb={3}>
      <BillingDialog openDialog={openBillingDialog} handleCloseDialog={() => setOpenBillingDialog(false)} />
      <InviteMemberDialog open={openDialog} handleClose={handleCloseDialog} />
      <MemberDrawer openDrawer={openDrawer} handleCloseDrawer={handleCloseDrawer} member={selectedMember} />
      <Card id="members">
        <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            Organization Members
          </MDTypography>
          <Can I="invite" an="members" passThrough>
            {allowed => (
              <DisabledTooltip allowed={allowed}>
                <MDButton variant="gradient" color="dark" size="small" type="submit" disabled={!allowed} onClick={handleOpenDialog}>
                  + Invite Member
                </MDButton>
              </DisabledTooltip>
            )}
          </Can>
        </MDBox>
        <DataTable
          tableId="teams"
          table={dataTableData}
          canSearch={true}
          onRowClick={(row, index) => {
            setSelectedMember(row.original.member)
            handleOpenDrawer()
          }}
        />
      </Card>
    </MDBox>
  )
}

export default OrganizationMembers
