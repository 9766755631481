import { ForgeEvent } from "./forge-event"
import { firestoreDb } from "firebase.init"
import { DocumentReference, Timestamp, doc } from "firebase/firestore"
import { EncryptionResult, ForgeEncryption } from "forge/core/services/encryption"
import { dateFromMap, documentReferenceFromMap } from "forge/core/utils/schema-parsing"
import { EncryptionConfig } from "types/encryption-config"

export class Milestone extends ForgeEvent {
  subject: string
  event: string
  contactRefs: DocumentReference[]
  originalRef?: DocumentReference
  day?: number
  month?: number
  year?: number
  triggerTexts?: string[]
  completed: boolean
  isOrganizationMilestone?: boolean
  createdBy: DocumentReference
  encryptedBy: DocumentReference
  createdAt?: Date
  updatedAt?: Date

  constructor({
    ref,
    name,
    startDate,
    endDate,
    contacts,
    contactRefs,
    originalRef,
    subject,
    event,
    day,
    month,
    year,
    triggerTexts,
    completed = false,
    isProcessing = false,
    isOrganizationMilestone = false,
    createdBy,
    encryptedBy,
    createdAt,
    updatedAt
  }: {
    ref?: DocumentReference
    name: string
    startDate: Date
    endDate?: Date
    contacts?: DocumentReference[]
    contactRefs: DocumentReference[]
    originalRef?: DocumentReference
    subject: string
    event: string
    day?: number
    month?: number
    year?: number
    triggerTexts?: string[]
    completed?: boolean
    isProcessing?: boolean
    isOrganizationMilestone?: boolean
    createdBy: DocumentReference
    encryptedBy: DocumentReference
    createdAt?: Date
    updatedAt?: Date
  }) {
    super(
      name,
      startDate ? Timestamp.fromDate(startDate) : Timestamp.now(),
      isProcessing,
      ref,
      endDate ? Timestamp.fromDate(endDate) : Timestamp.now(),
      contacts
    )
    this.subject = subject
    this.event = event
    this.contactRefs = contactRefs
    this.originalRef = originalRef
    this.day = day
    this.month = month
    this.year = year
    this.triggerTexts = triggerTexts
    this.completed = completed
    this.isOrganizationMilestone = isOrganizationMilestone
    this.createdBy = createdBy
    this.encryptedBy = encryptedBy
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  async toMap({
    toFirestore = false,
    encryptionConfig
  }: {
    toFirestore?: boolean
    encryptionConfig?: EncryptionConfig
  } = {}): Promise<any> {
    const isEncrypted = encryptionConfig && encryptionConfig.encrypted
    const encryptionResultName: EncryptionResult = isEncrypted
      ? await encryptionConfig.service.encrypt(
          this.name,
          encryptionConfig.encryptedForOrganization ? encryptionConfig.userProfileData.organization?.id : undefined,
          this.ref?.path
        )
      : {
          result: this.name,
          encrypter: "seald",
          encrypted: false
        }

    return {
      ref: toFirestore ? this.ref : this.ref?.path,
      name: encryptionResultName.result,
      startDate: toFirestore ? this.startDate : this.startDate.toMillis(),
      endDate: toFirestore ? this.startDate : this.startDate.toMillis(),
      contacts: this.contactRefs?.map(x => (toFirestore ? x : x?.path)),
      contactRefs: this.contactRefs?.map(x => (toFirestore ? x : x?.path)),
      originalRef: this.originalRef?.path,
      subject: this.subject,
      event: this.event,
      encrypted: encryptionResultName.encrypted,
      encrypter: encryptionResultName.encrypter,
      day: this.day,
      month: this.month,
      year: this.year,
      triggerTexts: this.triggerTexts,
      completed: this.completed,
      isProcessing: this.isProcessing,
      isOrganizationMilestone: this.isOrganizationMilestone,
      createdBy: toFirestore ? this.createdBy : this.createdBy?.path,
      encryptedBy: toFirestore ? this.encryptedBy : this.encryptedBy?.path,
      createdAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.createdAt?.valueOf(),
      updatedAt: toFirestore ? this.updatedAt ?? Timestamp.now() : this.updatedAt?.valueOf()
    }
  }

  static async fromMap(map: any, encryptionService: ForgeEncryption, organizationId?: string): Promise<Milestone> {
    let encryptedBy = documentReferenceFromMap(map.encryptedBy)

    let name =
      map.encrypted ?? false
        ? await encryptionService.decrypt({
            data: map.name,
            encrypter: map.encrypter
          })
        : map.name
    let startDate = dateFromMap(map.startDate)
    let endDate = dateFromMap(map.endDate)

    return new Milestone({
      ref: documentReferenceFromMap(map.ref),
      originalRef: documentReferenceFromMap(map.originalRef),
      name,
      startDate: startDate,
      endDate: endDate,
      contactRefs: map.contactRefs
        ? map.contactRefs.map((x: string | DocumentReference) => {
            if (x instanceof DocumentReference) {
              return x
            } else if (typeof x === "string") {
              return doc(firestoreDb, x)
            }
          })
        : null,
      subject: map.subject ?? "",
      event: map.event ?? "",
      day: map.day,
      month: map.month,
      year: map.year,
      triggerTexts: map.triggerTexts ?? [],
      completed: map.completed,
      isProcessing: map.isProcessing,
      isOrganizationMilestone: map.isOrganizationMilestone ?? false,
      createdBy: documentReferenceFromMap(map.createdBy),
      encryptedBy: encryptedBy,
      createdAt: dateFromMap(map.createdAt),
      updatedAt: dateFromMap(map.updatedAt)
    })
  }
}
