import React from "react"
import { Backdrop, Stack, useMediaQuery } from "@mui/material"
import MDTypography from "components/MDTypography"
import theme from "assets/theme"

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif"

interface Props {
  loading: boolean
  customHeight?: string | number
  customWidth?: string
  customTop?: string
  loadingText?: string
  loadingType?: string
  style?: React.CSSProperties
}

const ForgeLoading: React.FC<Props> = ({ loading, customHeight, customWidth = null, customTop, loadingText = "", loadingType = "", style }) => {
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  let imgStyle
  switch (loadingType) {
    case "small":
      imgStyle = { width: "40px", ...style }
      break
    case "medium":
      imgStyle = { width: "120px", ...style }
      break
    case "login":
      imgStyle = { width: "256px", ...style }
      break
    case "splash":
      imgStyle = { width: "25%", margin: "10px", ...style }
      break
    case "inline":
      imgStyle = { display: "block", ...style }
      break
    default:
      imgStyle = { width: "200px", ...style }
      break
  }

  if (loadingType) return <img hidden={!loading} src={spinningSphere} style={imgStyle} alt="Loading..." />

  const height = customHeight ?? "95vh"
  const width = customWidth ?? (largeScreen ? "40%" : "68%")
  const top = customTop ?? "35vh"

  return (
    <div style={{ height, width, background: "rgba(255, 255, 255, 0.5)", position: "fixed", zIndex: "99" }} hidden={!loading}>
      <Backdrop sx={{ top, background: "rgba(255, 255, 255, 0.5)", zIndex: theme => theme.zIndex.drawer + 1, position: "sticky" }} open={loading}>
        <Stack direction="column" spacing={1}>
          <img src={spinningSphere} style={imgStyle} alt="Loading..." />
          <MDTypography variant="h6" textAlign="center">
            {loadingText}
          </MDTypography>
        </Stack>
      </Backdrop>
    </div>
  )
}

export default ForgeLoading
