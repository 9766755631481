// @material-ui core components
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography"

// Settings page components

import { useContext, useEffect, useState } from "react"
import MDBox from "components/MDBox"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import SubscriptionFlow from "./SubscriptionFlow"
import SubscriptionDetails from "./SubscriptionDetails"
import { InvoiceContextProvider } from "forge/settings/services/InvoiceContext"
import MDButton from "components/MDButton"
import { AuthContext } from "context"
import { Backdrop, IconButton, Menu, MenuItem } from "@mui/material"
import { Can } from "forge/organization/rbac/CanContext"
import { MoreVert } from "@mui/icons-material"
import InviteMemberDialog from "forge/organization/members/components/InviteMemberDialog"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import ManualSubscriptionDetails from "./ManualSubscriptionDetails"

function SettingsBilling({ section }: { section: string }): JSX.Element {
  // State
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState<string>()
  const [manualSubscription, setManualSubscription] = useState<any>()
  const [individualPlan, setIndividualPlan] = useState<boolean>(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openInviteMemberDialog, setOpenInviteMemberDialog] = useState(false)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // Context
  const { userProfileData } = useContext(AuthContext)
  let doesUserBelongsToAnOrganization = userProfileData?.doesUserBelongsToAnOrganization
  const { organization, cancelSubscription } = useContext(OrganizationContext)

  useEffect(() => {
    if (organization?.stripeSubscriptionId) {
      setStripeSubscriptionId(organization?.stripeSubscriptionId)
      setIndividualPlan(false)
      return
    }

    if (userProfileData?.stripeSubscriptionId) {
      setStripeSubscriptionId(userProfileData?.stripeSubscriptionId)
      setIndividualPlan(true)
      return
    }

    if (organization?.manualSubscription) {
      setManualSubscription(organization?.manualSubscription)
      setIndividualPlan(false)
      return
    }

    if (userProfileData?.manualSubscription) {
      setManualSubscription(userProfileData?.manualSubscription)
      setIndividualPlan(true)
      return
    }

    setManualSubscription(undefined)
    setStripeSubscriptionId(undefined)
  }, [userProfileData, organization])

  const handleCancelSubscription = () => {
    setAnchorEl(null)
    setOpenCancelDialog(true)
  }

  const handleCloseCancelDialog = (result: boolean): any => {
    setOpenCancelDialog(false)
    if (result) {
      try {
        cancelSubscription(stripeSubscriptionId)
      } catch (err) {
        console.error(err)
        return null
      }
    }
  }

  const subscriptionDetails =
    (individualPlan && section === "settings") || (!individualPlan && section === "organization") ? (
      <Can I="view" this={"billing"} passThrough>
        {allowed =>
          allowed ? (
            <InvoiceContextProvider>
              <SubscriptionDetails />
            </InvoiceContextProvider>
          ) : (
            billingLackOfPermissions
          )
        }
      </Can>
    ) : (
      <></>
    )

  const manualSubscriptionDetails =
    (individualPlan && section === "settings") || (!individualPlan && section === "organization") ? (
      <Can I="view" this={"billing"} passThrough>
        {allowed => (allowed ? <ManualSubscriptionDetails /> : billingLackOfPermissions)}
      </Can>
    ) : (
      <></>
    )

  const billingLackOfPermissions = (
    <MDBox style={{ padding: "0px 24px 24px 24px", alignSelf: "center" }}>
      <MDTypography variant="button" color="text">
        Contact one of your organization's admins to manage billing.
      </MDTypography>
    </MDBox>
  )

  const cancelSubscriptionConfirmationDialog = (
    <ConfirmationDialog
      openDialog={openCancelDialog}
      handleCloseDialog={handleCloseCancelDialog}
      title="Are you sure you want to cancel your subscription?"
      description="Canceling will remove access to premium features. You will lose access at the end of your current billing cycle, and this action cannot be undone."
      confirmText="Confirm"
      confirmColor="error"
      denyVariant="contained"
    />
  )

  return (
    <Card id="billing" sx={{ overflow: "visible", marginBottom: section === "settings" ? 0 : 3 }}>
      {cancelSubscriptionConfirmationDialog}
      <InviteMemberDialog open={openInviteMemberDialog} handleClose={() => setOpenInviteMemberDialog(false)} />
      <Backdrop
        sx={{
          background: "rgba(255, 255, 255, 0.85)",
          zIndex: theme => 999,
          position: "absolute",
          borderRadius: "16px",
          overflow: "hidden"
        }}
        open={doesUserBelongsToAnOrganization && section === "settings"}
      >
        <MDTypography variant="button" color="text">
          It appears you work with an organization. Manage the organization’s billing by clicking <a href="/organization#billing">here</a>.
        </MDTypography>
      </Backdrop>
      <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
        <MDTypography variant="h5">Billing</MDTypography>
        <Can I="edit" this={"billing"}>
          {stripeSubscriptionId && (
            <>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="billing-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}
              >
                <MenuItem onClick={handleCancelSubscription}>Cancel Subscription</MenuItem>
                <MenuItem onClick={() => setOpenInviteMemberDialog(true)}>Invite Member</MenuItem>
              </Menu>
            </>
          )}
        </Can>
      </MDBox>
      {stripeSubscriptionId ? (
        subscriptionDetails
      ) : manualSubscription?.enabled ? (
        manualSubscriptionDetails
      ) : (
        <Can I="view" this={"billing"} passThrough>
          {allowed => (allowed ? <SubscriptionFlow /> : billingLackOfPermissions)}
        </Can>
      )}
    </Card>
  )
}

export default SettingsBilling
