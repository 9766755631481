import React, { useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import MDButton from "components/MDButton"
import MDBox from "components/MDBox"
import MDAlert from "components/MDAlert"

interface Props {
  openDialog: boolean
  handleCloseDialog: (result: boolean) => void
  contactName: string
  company: string
  setCompany: (value: string) => void
  location: string
  setLocation: (value: string) => void
  keywords: string
  setKeywords: (value: string) => void
}

const LinkedInSearchDialog: React.FC<Props> = ({
  openDialog,
  handleCloseDialog,
  contactName,
  company,
  setCompany,
  location,
  setLocation,
  keywords,
  setKeywords
}) => {
  const [showError, setShowError] = useState(false)

  const handleSubmit = () => {
    if (!company && !location && !keywords) {
      setShowError(true)
      return
    }

    setShowError(false)
    handleCloseDialog(true)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleCloseDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Help us fine tune our search for {contactName}</DialogTitle>
      <DialogContent>
        {showError && (
          <MDBox>
            <MDAlert color="error">At least one of the fields should have values to submit the search</MDAlert>
          </MDBox>
        )}
        <TextField
          style={{ width: "100%", marginTop: "15px" }}
          id="outlined-multiline-static"
          label="Company"
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
        <TextField
          style={{ width: "100%", marginTop: "15px" }}
          id="outlined-multiline-static"
          label="Location"
          value={location}
          onChange={e => setLocation(e.target.value)}
        />
        <TextField
          style={{ width: "100%", marginTop: "15px" }}
          id="outlined-multiline-static"
          label="Other Keywords"
          value={keywords}
          onChange={e => setKeywords(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <MDButton variant="outlined" onClick={() => handleCloseDialog(false)} color={"info"}>
          Cancel
        </MDButton>
        <MDButton onClick={handleSubmit} color="info">
          Search
        </MDButton>
      </DialogActions>
    </Dialog>
  )
}

export default LinkedInSearchDialog
