import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { AuthContext } from "context"

// MUI
import { Button, IconButton, Stack, Tab, Tabs } from "@mui/material"
import { Edit, Info } from "@mui/icons-material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel"

// Services
import { NewsContext } from "forge/outreach/news/services/NewsContext"
import { News } from "types/news/news"
import NewsHorizontalCard from "forge/outreach/news/components/NewsHorizontalCard"
import SearchTermTile from "../../../people/contacts/components/SearchTermTile"
import { parseKnowledge } from "forge/core/knowledgeParsing"
import KnowledgeEdit from "../KnowledgeEdit"
import KnowledgeApi from "forge/knowledge/services/api"
import { Can } from "forge/organization/rbac/CanContext"
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { MembersContext } from "forge/organization/members/services/MembersContext"
import { DocumentReference } from "firebase/firestore"
import MemberTile from "forge/organization/members/components/MemberTile"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import LaunchIcon from '@mui/icons-material/Launch';
import colors from "assets/theme/base/colors"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" }
})

function KnowledgeDetail({
  handleCloseDrawer,
  knowledge,
  displayKnowledge = true
}: {
  handleCloseDrawer: () => void
  knowledge: any
  displayKnowledge?: boolean
}) {
  // UI
  const classes = styles()

  // Context
  const { getCurrentUser, userId } = useContext(AuthContext)
  const { getNewsForSearchTerm } = useContext(NewsContext)
  const { getMember } = useContext(MembersContext)
  const { organization } = useContext(OrganizationContext)

  // Services
  let { user, encryptionService, userProfileData } = getCurrentUser()
  const knowledgeApi = new KnowledgeApi(user, userProfileData, organization, encryptionService)

  // State
  const [isAuthoredByUser, setIsAuthoredByUser] = useState<boolean>(false)
  const [creatorMember, setMemberCreator] = useState<ForgeOrganizationMember>()

  // Edit
  const [edit, setEdit] = useState<boolean>(false)

  // Tabs
  const [tabValue, setTabValue] = useState(1)
  const handleSetTabValue = (event: any, newValue: number) => setTabValue(newValue)

  // Search Term Selector
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  // Recent News
  const [news, setNews] = useState<News[]>([])

  // Topic Overview
  const [wikiPageMap, setWikiPageMap] = useState<{ [key: string]: any }>()
  const [wikiPageId, setWikiPageId] = useState<string | undefined>()

  useEffect(() => {
    knowledge.searchTerm = Array.from(
      new Set(knowledge.searchTerm
        .map((e: string) => e.trim())
        .filter(Boolean))
    ).sort();

    if (knowledge?.isOrganizationKnowledge && knowledge?.createdBy && knowledge.createdBy instanceof DocumentReference) {
      setMemberCreator(getMember(knowledge.createdBy.id))
    }

    ;(async () => {
      let wikiPageMap = await knowledgeApi.getKnowledgeWikiPageId(knowledge, undefined)

      if (wikiPageMap) {
        setWikiPageMap(wikiPageMap)
        for (const [key, value] of Object.entries(wikiPageMap)) {
          if (value.pageId) {
            setWikiPageId(value.pageId)
            break
          }
        }
      }
    })()

    if (knowledge?.searchTerm && knowledge?.searchTerm.length > 0) {
      // Get news for search term
      setNews(getNewsForSearchTerm(knowledge.searchTerm[0]))
    } else {
      setEdit(true)
    }
  }, [knowledge])

  useEffect(() => {
    if (!knowledge?.isOrganizationKnowledge) {
      setIsAuthoredByUser(true)
      return
    }

    setIsAuthoredByUser(creatorMember && creatorMember.ref.id === userId)
  }, [creatorMember, userId])

  const onDetailSearchTermSelected = (searchTerm: string, index: number) => {
    setSelectedIndex(index)
    if (wikiPageMap && wikiPageMap[searchTerm]) {
      setWikiPageId(wikiPageMap[searchTerm].pageId)
    }
  }

  const onEditCanceled = () => {
    if (knowledge?.searchTerm && knowledge?.searchTerm.length > 0) {
      setEdit(false)
    } else {
      handleCloseDrawer()
    }
  }

  const getWikipediaUrl = () => {
    return `https://en.m.wikipedia.org/?curid=${wikiPageId}`;
  }

  return displayKnowledge && edit ? (
    <KnowledgeEdit knowledge={knowledge} onEditCanceled={onEditCanceled} handleCloseDrawer={handleCloseDrawer} />
  ) : (
    <MDBox mx={3}>
      <MDBox mt={2} />
      {displayKnowledge && (
        <Stack direction="row" justifyContent="space-between" style={{ marginBottom: 8 }}>
          <MDBox>
            <MDTypography variant="body2" color="text" style={{ "white-space": "break-spaces" }}>
              {parseKnowledge(knowledge)}
            </MDTypography>
            {!isAuthoredByUser && creatorMember && <MemberTile member={creatorMember} size="small" />}
          </MDBox>
          <Can I="edit" a="knowledge" passThrough>
            {allowed => (
              <DisabledTooltip allowed={allowed && isAuthoredByUser} backgroundColor="grey">
                <IconButton onClick={allowed && isAuthoredByUser ? () => setEdit(!edit) : undefined}>
                  <Edit color={!allowed && isAuthoredByUser ? "disabled" : undefined} />
                </IconButton>
              </DisabledTooltip>
            )}
          </Can>
        </Stack>
      )}
      <Stack direction="column" spacing={1} style={{ marginBottom: "16px" }}>
        {knowledge?.searchTerm
          .map((e: string, index: number) => (
            <SearchTermTile
              searchTerm={e}
              isSelected={index === selectedIndex}
              logoUrl={wikiPageMap && wikiPageMap[e]?.imageUrl}
              onClick={() => onDetailSearchTermSelected(e, index)}
            />
          ))}
      </Stack>
      <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
        <Tab label="Recent News" {...a11yProps(0)} />
        <Tab label="Topic Overview" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        {news.length > 0 ? (
          news.map((article, index) => (
            <MDBox mb={1.5}>
              <NewsHorizontalCard key={index} article={article} />
            </MDBox>
          ))
        ) : (
          <Stack m={"auto"} mt={"38vh"} mb={"38vh"} direction="column" alignItems="center" spacing={0.5}>
            <Info style={{ color: "darkgray" }} />
            <MDTypography variant="h5" style={{ color: "darkgray" }}>
              No news found for this search term.
            </MDTypography>
          </Stack>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        {wikiPageId ? (
          <>
          <iframe
            title="Wikipedia iframe"
            src={getWikipediaUrl()}
            style={{ width: "100%", height: "80vh" }}
            frameBorder="0"
          ></iframe>
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 1000,
            }}
          >
            <Stack direction="row" spacing={1}>
              <IconButton
                size="large"
                href={getWikipediaUrl()}
                target="_blank"
                style={{
                  backgroundColor: colors.info.main,
                  color: colors.white.main,             
                  margin: "10px",           
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                }}
              >
                <LaunchIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </div>
        </>
        ) : (
          <Stack m={"auto"} mt={"38vh"} mb={"38vh"} direction="column" alignItems="center" spacing={0.5}>
            <Info style={{ color: "darkgray" }} />
            <MDTypography variant="h5" style={{ color: "darkgray" }}>
              Uh oh! We couldn't find this topic on Wikipedia.
            </MDTypography>
          </Stack>
        )}
      </CustomTabPanel>
    </MDBox>
  )
}

// Setting default values for the props of KnowledgeDetail
KnowledgeDetail.defaultProps = {
  onContactSelected: null
}

export default KnowledgeDetail
