import { MutableRefObject, useContext, useEffect, useMemo, useState } from "react"
import { ContactsContext } from "context"

// MUI
import { OutlinedInput, Stack, Tab, Tabs } from "@mui/material"
import { InfoOutlined, Search } from "@mui/icons-material"

// Material Dashboard
import MDBox from "components/MDBox"

// Services
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel"
import ContactCard from "../ContactCard"
import { Timestamp } from "firebase/firestore"
import AddContactCard from "../AddContactCard"
import MDTypography from "components/MDTypography"
import CreateContactDrawer from "forge/people/contacts/components/CreateContactDrawer"
import { usePrevious } from "forge/core/hooks/usePrevious"

function SelectContact({
  onContactSelected,
  onContactUnselected,
  drawerRef,
  filteredIds,
  recommendedIds,
  isOrganization = false,
  enforceContactTypeSeparation = false
}: {
  onContactSelected: (contact: any) => void
  onContactUnselected?: (contact: any) => void
  drawerRef?: MutableRefObject<HTMLDivElement> | null
  filteredIds: string[]
  recommendedIds: string[]
  isOrganization?: boolean
  enforceContactTypeSeparation?: boolean
}) {
  const { contacts } = useContext(ContactsContext)

  // Contacts
  const [selectedContacts, setSelectedContacts] = useState([])
  const [baseContacts, setBaseContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [contactsAmount, setContactsAmount] = useState(100)

  // Tabs
  const [tabValue, setTabValue] = useState(1)
  const handleSetTabValue = (event: any, newValue: number) => setTabValue(newValue)

  // Search
  const [searchQuery, setSearchQuery] = useState("")
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()

  // Add Contact
  const [openCreateContactDrawer, setOpenCreateContactDrawer] = useState(false)
  const handleOpenCreateDrawer = () => setOpenCreateContactDrawer(true)
  const handleCloseCreateDrawer = () => setOpenCreateContactDrawer(false)

  useEffect(() => {
    if (drawerRef) {
      const drawerNode = drawerRef.current
      if (drawerNode) {
        drawerNode.addEventListener("scroll", handleScroll)
      }

      // Clean up the event listener on unmount
      return () => {
        if (drawerNode) {
          drawerNode.removeEventListener("scroll", handleScroll)
        }
      }
    }
  }, [])

  const handleScroll = () => {
    if (Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight) {
      return
    }

    setContactsAmount(contactsAmount + 100)
  }

  useEffect(() => {
    const sortBySameNameFirst = (contactsList: any) => {
      const currentContact = contacts.find((contact: any) => filteredIds.includes(contact.ref?.id))

      const currentContactName = currentContact?.name?.toLowerCase()

      return contactsList
        .filter((contact: any) => !filteredIds.includes(contact.ref?.id))
        .sort((a: any, b: any) => {
          if (recommendedIds.includes(a?.id)) return -2
          if (recommendedIds.includes(b?.id)) return 2
          if (currentContactName && a?.name?.toLowerCase() === currentContactName) return -1
          if (currentContactName && b?.name?.toLowerCase() === currentContactName) return 1
          return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
        })
    }

    let sortedContacts
    switch (tabValue) {
      case 1:
        // Recent Contacts
        sortedContacts = sortBySameNameFirst(
          [...contacts].sort((a, b) => {
            if (a?.lastInteraction instanceof Timestamp && b?.lastInteraction instanceof Timestamp) {
              return b.lastInteraction.toMillis() - a.lastInteraction.toMillis()
            }
            return 0
          })
        )
        break
      case 2:
        // Priority Contacts
        sortedContacts = sortBySameNameFirst(contacts.filter((e: any) => e?.favorite || e?.favoriteByGoal))
        break
      default:
        // All Contacts
        sortedContacts = sortBySameNameFirst(contacts)
        break
    }

    setBaseContacts(sortedContacts)
  }, [contacts, tabValue, filteredIds, recommendedIds])

  useEffect(() => {
    setFilteredContacts(filterData(searchQuery, baseContacts).slice(0, contactsAmount))
  }, [searchQuery, baseContacts, contactsAmount])

  const filterData = (query: string, data: any) => {
    if (!query) {
      return data.filter((contact: any) => !filteredIds.includes(contact.ref?.id))
    }

    return data.filter((contact: any) => contact?.name?.toLowerCase().includes(query.toLowerCase()) && !filteredIds.includes(contact.ref?.id))
  }

  const onContactTapped = (contact: any) => {
    let selected: any[] = []
    if (selectedContacts.includes(contact)) {
      selected = [...selectedContacts]
      selected = selected.filter(e => e.ref?.id !== contact.ref?.id)
      onContactUnselected(contact)
    } else {
      selected = [...selectedContacts, contact]
      onContactSelected(contact)
    }

    setSelectedContacts(selected)
  }

  const onAddContactTapped = () => {
    let querySplit = searchQuery.split(" ")
    setFirstName(querySplit[0])

    if (querySplit.length > 1) {
      setLastName(querySplit[1])
    }

    return handleOpenCreateDrawer()
  }

  return (
    <MDBox mx={3}>
      <CreateContactDrawer
        openDrawer={openCreateContactDrawer}
        handleCloseDrawer={handleCloseCreateDrawer}
        enableNavigation={false}
        initialFirstName={firstName}
        initialLastName={lastName}
      />
      <OutlinedInput
        id="search-bar"
        className="text"
        onInput={e => {
          setSearchQuery((e.target as HTMLInputElement).value)
        }}
        placeholder="Search relationships"
        fullWidth={true}
        endAdornment={<Search />}
      />
      <MDBox mt={3} />
      <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
        <Tab label="All" {...a11yProps(0)} />
        <Tab label="Recent" {...a11yProps(1)} />
        <Tab label="Priority" {...a11yProps(2)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        {filteredContacts.map((contact: any) => {
          const isDisabled = enforceContactTypeSeparation && isOrganization !== contact.isOrganizationContact

          return (
            <ContactCard
              key={contact?.id}
              onClick={() => onContactTapped(contact)}
              contact={contact}
              isSelected={selectedContacts.includes(contact)}
              isFavoriteEnabled={false}
              enableScoreBadge={false}
              isDisabled={isDisabled}
              subtitle={isDisabled ? "Mixing personal contacts with organization contacts is not allowed." : undefined}
            />
          )
        })}
        {filteredContacts.length === 0 && (
          <>
            <AddContactCard onClick={onAddContactTapped} />
            <Stack m={"auto"} mt={"20vh"} direction="column" alignItems="center" spacing={0.5}>
              <InfoOutlined style={{ color: "darkgray" }} />
              <MDTypography variant="h5" sx={{ fontWeight: "normal" }} style={{ color: "darkgray" }}>
                No relationships match your current query.
              </MDTypography>
            </Stack>
          </>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        {filteredContacts.map((contact: any) => {
          const isDisabled = enforceContactTypeSeparation && isOrganization !== contact.isOrganizationContact

          return (
            <ContactCard
              key={contact?.id}
              onClick={() => onContactTapped(contact)}
              isSelected={selectedContacts.includes(contact)}
              contact={contact}
              isFavoriteEnabled={false}
              enableScoreBadge={false}
              isDisabled={isDisabled}
              subtitle={isDisabled && "Mixing personal contacts with organization contacts is not allowed."}
            />
          )
        })}
        {filteredContacts.length === 0 && (
          <>
            <AddContactCard onClick={onAddContactTapped} />
            <Stack m={"auto"} mt={"20vh"} direction="column" alignItems="center" spacing={0.5}>
              <InfoOutlined style={{ color: "darkgray" }} />
              <MDTypography variant="h5" sx={{ fontWeight: "normal" }} style={{ color: "darkgray" }}>
                No relationships match your current query.
              </MDTypography>
            </Stack>
          </>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        {filteredContacts.map((contact: any) => {
          const isDisabled = enforceContactTypeSeparation && isOrganization !== contact.isOrganizationContact

          return (
            <ContactCard
              key={contact?.id}
              onClick={() => onContactTapped(contact)}
              contact={contact}
              isSelected={selectedContacts.includes(contact)}
              isFavoriteEnabled={false}
              enableScoreBadge={false}
              isDisabled={isDisabled}
              subtitle={isDisabled && "Mixing personal contacts with organization contacts is not allowed."}
            />
          )
        })}
        {filteredContacts.length === 0 && (
          <>
            <AddContactCard onClick={onAddContactTapped} />
            <Stack m={"auto"} mt={"20vh"} direction="column" alignItems="center" spacing={0.5}>
              <InfoOutlined style={{ color: "darkgray" }} />
              <MDTypography variant="h5" sx={{ fontWeight: "normal" }} style={{ color: "darkgray" }}>
                No relationships match your current query.
              </MDTypography>
            </Stack>
          </>
        )}
      </CustomTabPanel>
    </MDBox>
  )
}

// Setting default values for the props of SelectContact
SelectContact.defaultProps = {
  onContactSelected: null
}

export default SelectContact
