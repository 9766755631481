import { useContext, useEffect, useRef, useState } from "react"
import { createUseStyles } from "react-jss"
import { AuthContext, ContactsContext } from "context"
import theme from "assets/theme"

// MUI
import { Box, Chip, Divider, Drawer, FormControl, Icon, Stack, Switch, TextField, useMediaQuery } from "@mui/material"
import { CalendarMonth, EditNoteOutlined, NotificationAddOutlined, Repeat, SportsScore } from "@mui/icons-material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"

// Services
import dayjs, { Dayjs } from "dayjs"
import CommitmentsApi from "../../services/api"
import { Commitment } from "types/commitment"
import { ForgeOpportunity } from "types/pipeline/opportunity"
import { Timestamp } from "firebase/firestore"
import SelectContact from "forge/core/components/SelectContact"

// Images
import { TimePicker } from "@mui/x-date-pickers"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" }
})

function CreateCommitmentDrawer({
  openDrawer,
  handleCloseDrawer,
  commitment,
  opportunity,
  contact,
  keepSelectedContact = false,
  recommendedIds = []
}: {
  openDrawer: boolean
  handleCloseDrawer: () => void
  commitment?: Commitment
  opportunity?: ForgeOpportunity
  contact?: any
  keepSelectedContact?: boolean
  recommendedIds?: string[]
}) {
  // UI
  const classes = styles()
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const drawerRef = useRef<HTMLDivElement | null>(null)
  const { getCurrentUser } = useContext(AuthContext)
  const { contacts, getContacts } = useContext(ContactsContext)
  const { organization } = useContext(OrganizationContext)

  // API
  const { user, encryptionService, userProfileData, userRef, memberRef } = getCurrentUser()
  const commitmentsApi = new CommitmentsApi(user, userProfileData, organization, encryptionService)

  // State
  const [selectedContact, setSelectedContact] = useState(contact)
  const [description, setDescription] = useState("")
  const [openClearDialog, setOpenClearDialog] = useState(false)
  const handleOpenClearDialog = () => setOpenClearDialog(true)
  const [closeDate, setCloseDate] = useState<Dayjs>(() => {
    // Current time
    const now = dayjs(new Date())

    // Set the desired time for today at 5 PM
    let targetTime = dayjs().hour(17).minute(0).second(0)

    // Check if current time is past 5 PM
    if (now.isAfter(targetTime)) {
      // If it's past 5 PM, set the target time for tomorrow at 5 PM
      targetTime = targetTime.add(1, "day")
    }

    return targetTime
  })
  const [repeat, setRepeat] = useState<string | null>()
  const [daysOfTheWeek, setDaysOfTheWeek] = useState<boolean[]>([true, true, true, true, true, true, true])
  const [notification, setNotification] = useState<number>(0)
  const [completed, setCompleted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const notificationOptions = { "3 days": 3, "5 days": 5, "1 week": 7, "2 weeks": 14 }

  useEffect(() => {
    if (commitment) {
      setDescription(commitment.name)
      setCloseDate(dayjs(commitment.startDate.toDate()))
      if (commitment.contacts.length > 0) {
        setSelectedContact(commitment.contacts[0])
      }
      setCompleted(commitment.completed)
    }
  }, [commitment])

  useEffect(() => {
    if (contact) {
      setSelectedContact(contact)
    }
  }, [contact])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)

    if (selectedContact) {
      if (commitment) {
        commitment.name = description
        commitment.startDate = Timestamp.fromDate(closeDate.toDate())
        commitment.endDate = Timestamp.fromDate(closeDate.toDate())
        commitment.enableReminder = notification != null
        commitment.reminderDays = notification
        commitment.frequency = repeat?.toLowerCase()
        commitment.dailyFrequencyDays = daysOfTheWeek
        commitment.completed = completed
        await commitmentsApi.updateCommitment(selectedContact, commitment)
        handleClose()
        return
      }

      let newCommitment = new Commitment({
        contacts: [selectedContact],
        contactRefs: [selectedContact.ref],
        name: description,
        startDate: closeDate.toDate(),
        endDate: closeDate.toDate(),
        enableReminder: notification != null,
        reminderDays: notification,
        frequency: repeat?.toLowerCase(),
        dailyFrequencyDays: daysOfTheWeek,
        opportunityId: opportunity?.id,
        createdBy: memberRef ?? userRef,
        encryptedBy: memberRef ?? userRef
      })

      await commitmentsApi.createCommitment(selectedContact, newCommitment, opportunity)
      handleClose()
      return
    }
  }

  const handleSecondaryAction = async () => {
    if (commitment && selectedContact) {
      commitment.completed = true
      await commitmentsApi.updateCommitment(selectedContact, commitment)
    } else {
      handleOpenClearDialog()
    }
  }

  const handleClose = () => {
    setLoading(false)
    setDescription("")
    setCloseDate(dayjs(new Date()))

    if (!keepSelectedContact) {
      setSelectedContact(undefined)
    }

    return handleCloseDrawer()
  }

  const handleCloseClearDialog = async (result: boolean) => {
    setOpenClearDialog(false)
    if (result) {
      setDescription("")
      setCloseDate(dayjs(new Date()))
      setRepeat(null)
      setDaysOfTheWeek([true, true, true, true, true, true, true])
      setNotification(0)
      setCompleted(false)
    }
  }

  return (
    <Drawer
      ref={drawerRef}
      anchor="right"
      open={openDrawer}
      onClose={handleClose}
      onScroll={e => console.log(e)}
      PaperProps={{
        sx: {
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      <ConfirmationDialog
        openDialog={openClearDialog}
        handleCloseDialog={handleCloseClearDialog}
        title="Are you sure you want to clear the fields?"
        description=""
        confirmText="Clear"
        denyVariant="outlined"
      />
      <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
        <ForgeLoading loading={loading} />

        {/* Header */}
        <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
          <MDBox>
            <MDTypography variant="h5">
              {selectedContact ? `What did you commit to do for ${selectedContact?.firstName}?` : "Who did you make a commitment to?"}
            </MDTypography>
          </MDBox>
          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `${size.lg} !important`,
              color: dark.main,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)"
            })}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider />

        {/* Body */}

        {selectedContact ? (
          <MDBox style={{ display: "contents" }}>
            <MDBox style={{ width: "100%", flex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MDBox px={3}>
                  <MDBox className={classes.formTile}>
                    <EditNoteOutlined className={classes.formIcon} />
                    <TextField
                      id="description"
                      label="Write commitment here..."
                      variant="outlined"
                      style={{ flex: 1 }}
                      multiline
                      minRows={5}
                      maxRows={10}
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </MDBox>
                  <MDBox className={classes.formTile}>
                    <CalendarMonth className={classes.formIcon} />
                    <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                      Due Date
                    </MDTypography>
                    <Box style={{ flex: 1 }} />
                    <DatePicker format="MMM DD, YYYY" value={closeDate} onChange={setCloseDate} />
                    <TimePicker value={closeDate} onChange={setCloseDate} />
                  </MDBox>
                  <MDBox className={classes.formTile}>
                    <Repeat className={classes.formIcon} />
                    <MDBox>
                      <MDTypography variant="body2" color="text" style={{ alignSelf: "center" }}>
                        Repeat
                      </MDTypography>
                      <Stack direction="row" useFlexGap flexWrap="wrap">
                        {["Daily", "Weekly", "Monthly", "Yearly"].map((e: any) => (
                          <Chip
                            key={e}
                            label={e}
                            style={{
                              margin: "4px",
                              background: e == repeat ? "black" : null,
                              color: e == repeat ? "white" : null
                            }}
                            onClick={() => setRepeat(e)}
                          />
                        ))}
                      </Stack>
                      {repeat && repeat == "Daily" && (
                        <Stack direction="row" useFlexGap flexWrap="wrap">
                          {["M", "T", "W", "T", "F", "S", "S"].map((e: any, index: number) => {
                            return (
                              <Chip
                                key={e}
                                label={e}
                                style={{
                                  margin: "4px",
                                  background: daysOfTheWeek[index] ? "black" : null,
                                  color: daysOfTheWeek[index] ? "white" : null
                                }}
                                onClick={() => {
                                  const newArray = [...daysOfTheWeek] // Create a copy of the previous array
                                  newArray[index] = !newArray[index] // Toggle the value at the specified index
                                  setDaysOfTheWeek(newArray)
                                }}
                              />
                            )
                          })}
                        </Stack>
                      )}
                    </MDBox>
                  </MDBox>
                  <MDBox className={classes.formTile}>
                    <NotificationAddOutlined className={classes.formIcon} />
                    <MDBox>
                      <MDTypography variant="body2" color="text" style={{ alignSelf: "center" }}>
                        Heads up
                      </MDTypography>
                      <Stack direction="row" useFlexGap flexWrap="wrap">
                        {Object.entries(notificationOptions).map(([key, value]: any) => {
                          const differenceInDays = closeDate.diff(dayjs(), "day")
                          let isDisabled = differenceInDays < value

                          return (
                            <Chip
                              key={key}
                              disabled={isDisabled}
                              label={key}
                              style={{
                                margin: "4px",
                                background: value === notification ? "black" : null,
                                color: value === notification ? "white" : null
                              }}
                              onClick={() => setNotification(value)}
                            />
                          )
                        })}
                      </Stack>
                    </MDBox>
                  </MDBox>
                  {commitment && (
                    <MDBox className={classes.formTile}>
                      <SportsScore className={classes.formIcon} />
                      <MDTypography variant="body2" color="text" style={{ alignSelf: "center" }}>
                        Completed
                      </MDTypography>
                      <Box style={{ flex: 1 }} />
                      <Switch
                        checked={completed}
                        onChange={(_, checked) => {
                          setCompleted(checked)
                        }}
                      />
                    </MDBox>
                  )}
                </MDBox>
              </LocalizationProvider>
            </MDBox>

            {/* Footer */}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                p: 1.5,
                pb: 2,
                borderTop: "1px solid",
                borderColor: "divider",
                justifyContent: "space-between"
              }}
            >
              <MDButton variant="text" color={commitment ? "error" : "dark"} onClick={handleSecondaryAction}>
                {commitment ? "Delete" : "Clear"}
              </MDButton>
              <MDButton variant="gradient" color="info" type="submit">
                {commitment ? "Update" : "Save"} Commitment
              </MDButton>
            </Box>
          </MDBox>
        ) : (
          <SelectContact
            onContactSelected={contact => setSelectedContact(contact)}
            drawerRef={drawerRef}
            filteredIds={[]}
            recommendedIds={recommendedIds}
          />
        )}
      </FormControl>
    </Drawer>
  )
}

// Setting default values for the props of CreateCommitment
CreateCommitmentDrawer.defaultProps = {
  handleCloseDrawer: null
}

export default CreateCommitmentDrawer
