import { EditCalendar, EmojiObjectsOutlined, IosShare, NorthEast, QuestionMark, SouthEast, TaskAlt } from "@mui/icons-material"
import { Avatar, Dialog, Divider, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import CreateEventDrawer from "forge/calendar/components/CreateEventDrawer"
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import ForgeCta from "forge/core/components/ForgeCta"
import { smartTime } from "forge/core/utilities"
import AddKnowledgeDrawer from "forge/knowledge/components/AddKnowledgeDrawer"
import { NewsContext } from "forge/outreach/news/services/NewsContext"
import React, { useContext, useState } from "react"

function ContactWarmthDialog({
  open,
  onClose,
  userContact,
  contact,
  isOwnUser,
  relationshipScore,
  relationshipColor
}: {
  open: boolean
  onClose?: () => void
  userContact: any
  contact: any
  isOwnUser: boolean
  relationshipScore: number
  relationshipColor: string
}) {
  // Context
  const { news } = useContext(NewsContext)

  // State
  const [openSaveInsightDrawer, setOpenSaveInsightDrawer] = useState(false)
  const handleOpenInsightDrawer = () => {
    onClose()
    return setOpenSaveInsightDrawer(true)
  }
  const handleCloseInsightDrawer = () => setOpenSaveInsightDrawer(false)
  const [openCreateCommitmentDrawer, setOpenCreateCommitmentDrawer] = useState(false)
  const handleOpenCommitmentDrawer = () => {
    onClose()
    return setOpenCreateCommitmentDrawer(true)
  }
  const handleCloseCommitmentDrawer = () => setOpenCreateCommitmentDrawer(false)
  const [openCreateEventDrawer, setOpenCreateEventDrawer] = useState(false)
  const handleOpenCreateEventDrawer = () => {
    onClose()
    return setOpenCreateEventDrawer(true)
  }
  const handleCloseCreateEventDrawer = () => setOpenCreateEventDrawer(false)

  const vars = { ...(contact?.relationshipData ?? {}), ...(contact?.questionsVars ?? {}) }
  console.log("vars", vars)

  const teams = (() => {
    let counter = 0
    for (let i = 1; i < 7; i++) {
      if (vars[`team${i}`]) counter++
    }
    return counter
  })()

  const userFirstName = isOwnUser ? "Your" : userContact?.firstName
  const userFirstNamePossessive = isOwnUser ? "Your" : `${userContact?.firstName}'s`
  const contactFirstName = contact?.firstName

  return (
    <React.Fragment>
      <AddKnowledgeDrawer
        contact={contact}
        openDrawer={openSaveInsightDrawer}
        handleCloseDrawer={handleCloseInsightDrawer}
        keepSelectedContact={true}
      />
      <CreateCommitmentDrawer contact={contact} openDrawer={openCreateCommitmentDrawer} handleCloseDrawer={handleCloseCommitmentDrawer} />
      <CreateEventDrawer openDrawer={openCreateEventDrawer} handleCloseDrawer={handleCloseCreateEventDrawer} />
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            boxShadow: "none",
            background: "white"
          },
          sx: {
            borderRadius: "16px",
            padding: "24px"
          }
        }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignContent="center" alignItems="center" alignSelf="center">
            <ForgeContactAvatar
              contact={userContact}
              enableScoreBadge={false}
              diameter={56}
              warmthRingThickness={6}
              override={{
                warmthScore: relationshipScore,
                isUserContact: false
              }}
            />
            <div
              style={{
                width: "24px",
                height: "10px" /* Adjust the height as needed */,
                background: relationshipColor,
                marginTop: 0
              }}
            />
            {isNaN(relationshipScore) ? (
              <Avatar style={{ width: "32px", height: "32px", position: "absolute", zIndex: "1", marginTop: "-12px" }}>
                <QuestionMark style={{ color: "white" }} fontSize="medium" />
              </Avatar>
            ) : (
              <Avatar
                style={{
                  width: "32px",
                  height: "32px",
                  background: relationshipColor,
                  color: relationshipScore >= 20 && relationshipScore < 80 ? "black" : "white",
                  fontSize: "16px"
                }}
              >
                {relationshipScore}
              </Avatar>
            )}
            <div
              style={{
                width: "24px",
                height: "10px" /* Adjust the height as needed */,
                background: relationshipColor,
                marginTop: 0
              }}
            />
            <ForgeContactAvatar contact={contact} enableScoreBadge={false} diameter={56} warmthRingThickness={6} />
          </Stack>
          <Divider style={{ marginTop: 20, marginBottom: 12 }} />
          <Stack direction="column" spacing={1}>
            <MDTypography variant="body2" style={{ alignSelf: "center" }}>
              {userFirstNamePossessive} <b>knowledge</b> about {contactFirstName}
            </MDTypography>
            {/* Listening Var */}
            {/* Kids */}
            {vars.child1name || vars.child1birthday
              ? (() => {
                  const knownVars = []
                  if (vars.child1name) knownVars.push("names")
                  if (vars.child1birthday) knownVars.push("birthdays")
                  return <WarmthPointTile text={`Kids' ${knownVars.join(" & ")}`} />
                })()
              : (vars.totalchildren || vars.child1gender || vars.child1age) &&
                (() => {
                  const knownVars = []
                  if (vars.totalchildren) knownVars.push("Number of kids")
                  if (vars.child1gender) knownVars.push("genders")
                  if (vars.child1age) knownVars.push("ages")
                  if (vars.child1birthday) knownVars.push("birthdays")
                  return <WarmthPointTile text={`${vars.totalchildren ? "" : "Kids' "}${knownVars.join(", ")}`} />
                })()}
            {/* Relationship */}
            {vars.anniversary || vars.so
              ? (() => {
                  const knownVars = []
                  if (vars.anniversary) knownVars.push("Anniversary")
                  if (vars.so) knownVars.push("Significant other's name")
                  return <WarmthPointTile text={knownVars.join(" & ")} />
                })()
              : vars.relationshipnow && <WarmthPointTile text={`Relationship Status`} />}

            {/* Home Address */}
            {vars.homeAddress && <WarmthPointTile text={`Home Address`} />}

            {/* Personal Email */}
            {vars.personalEmail && <WarmthPointTile text={`Personal Email`} />}

            {/* Relatives Birthdays */}
            {vars.relativesBirthdays && <WarmthPointTile text={`Relatives Birthdays`} />}

            {/* Length of time known */}
            {vars.lengthOfTimeKnown && <WarmthPointTile text={`Length of time known`} />}

            {/* Diseases (contact’s family members) */}
            {vars.relativesDiseases && <WarmthPointTile text={`Family members' health`} />}

            {/* Share College or School */}
            {vars.shareCollege && <WarmthPointTile text={`Share College or School`} />}

            {/* Astrological Sign */}
            {vars.astrologicalSign && <WarmthPointTile text={`Astrological Sign`} />}

            {/* Religion */}
            {vars.religion && <WarmthPointTile text={`Religion`} />}

            {/* Political Leaning */}
            {vars.politicalLeaning && <WarmthPointTile text={`Political Leaning`} />}

            {/* Sports Team */}
            {vars.team1 && <WarmthPointTile text={`${teams} sports team${teams > 1 ? "s" : ""}`} />}

            {/* Pets */}
            {vars.haspets && <WarmthPointTile text={`Has pets`} />}

            {/* Hometown */}
            {vars.hometown && <WarmthPointTile text={`Hometown`} />}

            {!(
              vars.child1name ||
              vars.child1birthday ||
              vars.totalchildren ||
              vars.child1gender ||
              vars.child1age ||
              vars.anniversary ||
              vars.so ||
              vars.relationshipnow ||
              vars.homeAddress ||
              vars.personalEmail ||
              vars.relativesBirthdays ||
              vars.lengthOfTimeKnown ||
              vars.relativesDiseases ||
              vars.shareCollege ||
              vars.astrologicalSign ||
              vars.religion ||
              vars.politicalLeaning ||
              vars.team1 ||
              vars.hometown
            ) && (
              <WarmthPointEmptyTile
                text={`It's not clear that you know any key personal details about ${contactFirstName}\nTry asking ${contactFirstName} questions from the list under 'Listen'.`}
                cta={`What have you learned from ${contactFirstName}?`}
                icon={<EmojiObjectsOutlined fontSize="small" />}
                onClick={handleOpenInsightDrawer}
              />
            )}
          </Stack>
          <Divider style={{ marginTop: 20, marginBottom: 12 }} />
          <Stack direction="column" spacing={1}>
            <MDTypography variant="body2" style={{ alignSelf: "center" }}>
              {userFirstNamePossessive} <b>meetings</b> with {contactFirstName}
            </MDTypography>
            {/* Meetings */}
            {vars.meetingsAmount > 0 && (
              <WarmthPointTile text={`${vars.meetingsAmount} scheduled meetings in the last 6 months`} isPositive={vars.meetingsAmount > 2} />
            )}
            {/* {vars.averageMeetingDuration && <WarmthPointTile text={`Average duration of scheduled meetings is ${vars.averageMeetingDuration.toFixed(0)} mins`} />} */}
            {vars.lastMeetingDate && (
              <WarmthPointTile text={`Last scheduled meeting was ${smartTime(vars.lastMeetingDate.toDate())?.toLowerCase()}`} />
            )}
            {!(vars.meetingsAmount > 0 || vars.lastMeetingDate) && (
              <WarmthPointEmptyTile
                text={`No recent or scheduled meetings including ${userFirstName} & ${contactFirstName}`}
                cta={`Schedule a meeting now`}
                icon={<EditCalendar fontSize="small" />}
                onClick={handleOpenCreateEventDrawer}
              />
            )}
          </Stack>
          <Divider style={{ marginTop: 20, marginBottom: 12 }} />
          <Stack direction="column" spacing={1}>
            <MDTypography variant="body2" style={{ alignSelf: "center" }}>
              {userFirstNamePossessive} <b>behaviors</b> towards {contactFirstName}
            </MDTypography>
            {/* Commitments */}
            {vars.createdCommitments > 0 && typeof vars.completedCommitments === "number" && (
              <WarmthPointTile
                text={`${vars.completedCommitments} of ${vars.createdCommitments} commitments completed${
                  vars.createdCommitments && vars.completedCommitmentsOnTime ? `, of which ${vars.completedCommitmentsOnTime} were on time,` : ""
                } in the last 6 months`}
                isPositive={(vars.completedCommitmentsOnTime ?? 0) / vars.createdCommitments >= 0.7}
              />
            )}
            {!vars.createdCommitments && (
              <WarmthPointEmptyTile
                text={`No commitments in the last 6 months`}
                cta={`What have you promised to do for ${contactFirstName}?`}
                icon={<TaskAlt fontSize="small" />}
                onClick={handleOpenCommitmentDrawer}
              />
            )}

            {/* Shared Articles */}
            {vars.sharedArticles > 0 && (
              <WarmthPointTile
                text={
                  vars.sharedArticles > 2
                    ? `${vars.sharedArticles} articles shared in the last 6 months`
                    : `Only one article shared in the last 6 months`
                }
                isPositive={vars.sharedArticles > 2}
                addMarginBottom={vars.createdCommitments > 0}
              />
            )}
            {!(vars.sharedArticles > 0) && (
              <WarmthPointEmptyTile
                text={`No shared articles in the last 6 months`}
                cta={news.length > 0 ? `Reach out with an icebreaker` : `Add topics of interest to ${contactFirstName} to discover icebreakers`}
                icon={<IosShare fontSize="small" />}
                onClick={handleOpenInsightDrawer}
              />
            )}
          </Stack>
        </Stack>
      </Dialog>
    </React.Fragment>
  )
}

export const WarmthPointTile = ({
  text,
  isPositive = true,
  addMarginBottom = false
}: {
  text: string
  isPositive?: boolean
  addMarginBottom?: boolean
}) => {
  return (
    <Stack direction="row" alignContent="center" alignItems="center" spacing={1}>
      {isPositive ? <NorthEast style={{ color: "#ff8d00" }} /> : <SouthEast style={{ color: "#0062ff" }} />}
      <MDTypography variant="body2" fontWeight="400" fontSize="14px" style={{ marginBotton: addMarginBottom ? 5 : 0 }}>
        {text}
      </MDTypography>
    </Stack>
  )
}

export const WarmthPointEmptyTile = ({ text, cta, icon, onClick }: { text: string; cta?: string; icon?: JSX.Element; onClick?: () => void }) => {
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" alignContent="center" alignItems="center" spacing={1}>
        <SouthEast style={{ color: "#0062ff" }} />
        <MDTypography
          variant="body2"
          fontWeight="400"
          fontSize="14px"
          style={{
            color: "gray",
            whiteSpace: "pre-line",
            textAlign: "center",
            width: "100%"
          }}
        >
          {text}
        </MDTypography>
      </Stack>
      {cta && (
        <MDBox width={"90%"} alignSelf={"center"}>
          <ForgeCta cta={cta} icon={icon ?? <EmojiObjectsOutlined />} onClick={onClick} fontWeight="normal" fontSize="14px" buttonSize="2rem" />
        </MDBox>
      )}
    </Stack>
  )
}

export default ContactWarmthDialog
