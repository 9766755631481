import React, { useEffect, useState } from "react"

const useGetElementWidth = (elementRef: React.MutableRefObject<any>, ...rest: any[]) => {
  const [elementWidth, setElementWidth] = useState(0)
  const [elementHeight, setElementHeight] = useState(0)

  useEffect(() => {
    if (elementRef.current) {
      setElementWidth(elementRef.current.clientWidth)
      setElementHeight(elementRef.current.clientHeight)
    }
  }, [elementRef, window.innerWidth, ...rest])

  return { elementWidth, elementHeight }
}

export default useGetElementWidth
