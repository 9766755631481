import MDSnackbar from "components/MDSnackbar"
import { useEffect, useState } from "react"

function NetworkStatusIndicator() {
  // State
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [isPoorConnection, setIsPoorConnection] = useState(false)

  // Function to check network quality
  const checkNetworkQuality = async () => {
    if (!isOnline) return

    if ("connection" in navigator) {
      const { effectiveType, downlink } = navigator.connection
      setIsPoorConnection(effectiveType === "2g" || downlink < 0.5)
    } else {
      // Fallback for unsupported browsers like Safari
      const url = "https://firebasestorage.googleapis.com/v0/b/forge-development.appspot.com/o/connection_quality_checker.png?alt=media"
      const startTime = performance.now()
      try {
        await fetch(url, { method: "GET", cache: "no-store" })
        const duration = performance.now() - startTime
        console.log(duration)
        setIsPoorConnection(duration > 2000)
      } catch (error) {
        setIsPoorConnection(true) // Assume poor connection if the request fails
      }
    }
  }

  // Effect for online/offline status
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
      checkNetworkQuality() // Check quality when back online
    }
    const handleOffline = () => {
      setIsOnline(false)
      setIsPoorConnection(false) // Reset quality check if offline
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  // Periodically check network quality when online
  useEffect(() => {
    if (isOnline) {
      checkNetworkQuality()
      const interval = setInterval(checkNetworkQuality, 30000) // Check every 30 seconds
      return () => clearInterval(interval)
    }
  }, [isOnline])

  return (
    <MDSnackbar
      color={!isOnline ? "error" : isPoorConnection ? "warning" : "info"}
      icon="warning"
      title={!isOnline ? "Network Offline" : isPoorConnection ? "Low Quality Network" : ""}
      content={
        !isOnline
          ? "You are offline. Some features may not work."
          : isPoorConnection
            ? "Your internet connection is poor. Please check your network."
            : ""
      }
      dateTime=""
      open={!isOnline || isPoorConnection}
      close={() => {
        setIsOnline(true)
        setIsPoorConnection(false)
      }}
      bgWhite
    />
  )
}

export default NetworkStatusIndicator
