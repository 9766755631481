import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"

import forgeLogo from "assets/forge/images/forge.png"
import { Box, Typography } from "@mui/material"
import { AuthContext, MaterialUIControllerProvider } from "context"
import { useContext } from "react"

function WelcomeDialog({ open, onContinue, onClose }: { open: boolean; onContinue: () => void; onClose?: () => void }) {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { user } = getCurrentUser()

  return (
    <MaterialUIControllerProvider>
      <React.Fragment>
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            style: {
              color: "white",
              backgroundColor: "black",
              boxShadow: "none"
            },
            sx: { borderRadius: "16px" }
          }}
          sx={{ fontFamily: "Montserrat, sans-serif" }}
        >
          <img src={forgeLogo} alt="Forge" style={{ margin: "auto", marginTop: "32px", width: "25%" }} />
          <DialogContent style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <Typography variant="h5" fontWeight="600" align="center" sx={{ fontFamily: "inherit" }}>
              We put the 'R' in CRM.
            </Typography>
            <Box style={{ maxWidth: "500px", margin: "0 auto" }}>
              <DialogContentText color={"white !important"} align="center" sx={{ mt: 3, fontFamily: "inherit" }}>
                Hello! 👋
              </DialogContentText>
              <DialogContentText color={"white !important"} align="center" sx={{ mt: 1.5, mb: 1, fontFamily: "inherit" }}>
                We’re delighted to have you onboard! We've built Forge to help you supercharge your relationships and revenue.
              </DialogContentText>
              <DialogContentText color={"white !important"} align="center" sx={{ mt: 2, mb: 1, fontFamily: "inherit" }}>
                It looks like this is a new account for{" "}
                <Typography fontWeight="bold" sx={{ fontFamily: "inherit" }}>
                  {user?.email}
                </Typography>
              </DialogContentText>
              <DialogContentText color={"white !important"} align="center" sx={{ mt: 1.5, mb: 1, fontFamily: "inherit" }}>
                Just a heads up—your contact sync may take a few minutes to complete. Please hang tight, and thanks for your patience!
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="submit" style={{ color: "white", textTransform: "none" }} onClick={() => onClose()}>
              Logout
            </Button>
            <Button type="submit" style={{ color: "white", textTransform: "none" }} onClick={() => onContinue()}>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </MaterialUIControllerProvider>
  )
}

export default WelcomeDialog
