/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @fullcalendar components
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"

// @mui material components
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot"

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context"
import { EventContentArg, EventSourceInput } from "@fullcalendar/core"
import { useEffect, useRef, useState } from "react"
import MDButton from "components/MDButton"
import { IconButton, Stack } from "@mui/material"
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material"
import { useLocation } from "react-router-dom"
import { triggerToastNotification } from "forge/shared/utils"
import { NOTIFICATION_TYPES } from "forge/shared/constants"

// Declaring props types for the Calender
interface Props {
  header?: {
    title?: string
    date?: string
  }
  [key: string]: any
}

function Calendar({ header, initialView, scrollTime, ...rest }: Props): JSX.Element {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const calendarRef = useRef<FullCalendar>()
  const [date, setDate] = useState<Date>(new Date())
  const [headerDate, setHeaderDate] = useState<string>()

  const { state } = useLocation()

  const validClassNames = ["primary", "secondary", "info", "success", "warning", "error", "light", "dark"]

  const events: EventSourceInput = rest.events || [];

  const eventContent = ({ event }: EventContentArg) => {
    return (
      <div
        style={{
          fontWeight: "bold",
          lineHeight: "1rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          height: "inherit",
          padding: "4px",
          fontSize: "smaller",
          color: event.textColor,
          backgroundImage: event.backgroundColor,
        }}
      >
        {event.title}
      </div> // Apply bold font weight
    )
  }

  useEffect(() => {
    if (date) {
      setHeaderDate(getHeader(date))
    }
  }, [date])

  useEffect(() => {
    if (state?.deletedCalendarEvent) {
      // trigger deleted notification
      triggerToastNotification({ notificationMessage: "Deleted successfully", notificationType: NOTIFICATION_TYPES.SUCCESS })
      const deletedEventDate = new Date(state.event)
      setDate(deletedEventDate)
    }
    if (state?.movedCalendarEvent) {
      // trigger changed event from calendar notification
      triggerToastNotification({ notificationMessage: "Updated successfully", notificationType: NOTIFICATION_TYPES.SUCCESS })
      const movedCalendarDate = new Date(state.event)
      setDate(movedCalendarDate)
    }
  }, [state])

  const getFirstAndLastDayOfWeek = (date: Date): { firstDayOfWeek: Date; lastDayOfWeek: Date } => {
    const currentDayOfWeek = date.getDay() // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const diff = currentDayOfWeek === 0 ? 0 : currentDayOfWeek // Calculate difference to Sunday
    const firstDayOfWeek = new Date(date)
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() - diff) // Set to Sunday of the current week

    const lastDayOfWeek = new Date(firstDayOfWeek)
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6) // Add 6 days to get Saturday

    return { firstDayOfWeek, lastDayOfWeek }
  }

  const getHeader = (currentDate: Date) => {
    if (initialView === "timeGridWeek") {
      const { firstDayOfWeek, lastDayOfWeek } = getFirstAndLastDayOfWeek(currentDate)

      return `${firstDayOfWeek.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        timeZone: "UTC"
      })} - ${lastDayOfWeek.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        timeZone: "UTC"
      })}`
    }

    return currentDate.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric"
    })
  }

  const handlePrevButtonClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current!.getApi() // get the FullCalendar API from the ref
      calendarApi.prev() // call the prev method to navigate to the previous period
      setDate(calendarApi.getDate())
    }
  }

  const handleNextButtonClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current!.getApi() // get the FullCalendar API from the ref
      calendarApi.next() // call the prev method to navigate to the previous period
      setDate(calendarApi.getDate())
    }
  }

  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current!.getApi() // get the FullCalendar API from the ref
      calendarApi.today() // call the prev method to navigate to the previous period
      setDate(calendarApi.getDate())
    }
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" style={{ paddingRight: "16px" }}>
        <MDBox pt={header.title || header.date ? 1 : 0} px={2} lineHeight={1}>
          {header.title ? (
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {header.title}
            </MDTypography>
          ) : null}

          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {headerDate}
          </MDTypography>
        </MDBox>
        <Stack direction="row" spacing={1}>
          <MDButton variant="outlined" color="dark" size="small" onClick={handleTodayButtonClick}>
            Today
          </MDButton>
          <IconButton onClick={handlePrevButtonClick}>
            <ArrowBackIosRounded fontSize="small" />
          </IconButton>
          <IconButton onClick={handleNextButtonClick}>
            <ArrowForwardIosRounded fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          {...rest}
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          height="100%"
          initialView={initialView ?? undefined} // Set the initial view to timeGridWeek
          dayCount={30} // Show 4 weeks (adjust as needed)
          nowIndicator // Show a line indicating the current time
          eventContent={eventContent}
          scrollTime={scrollTime}
        />
      </CalendarRoot>
    </>
  )
}

// Declaring default props for Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: ""
  }
}

export default Calendar
