// MUI
import { Paper, Stack, Switch } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import ShareActions from "../../../core/components/ShareActions"
import MilestonesApi from "../../services/api"
import { parseMilestone } from "forge/core/knowledgeParsing"
import { ForgeAvatarGroup } from "forge/core/components/ForgeAvatar"
import { useContext, useEffect, useState } from "react"
import AddContactDetailDialog from "forge/people/contacts/components/AddContactDetailDialog"
import { AuthContext } from "forge/auth/services/AuthContext"
import ContactsApi from "forge/people/contacts/services/api"
import { ContactField } from "types/contact/contact-field"
import MilestonesFirestore from "forge/milestones/services/firestore"

function MilestoneCard({ milestone, noElevation = false }: { milestone: any; noElevation?: boolean }): JSX.Element {
  // Context
  const { getCurrentUser } = useContext(AuthContext)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)
  const milestonesFirestore = new MilestonesFirestore(user, userProfileData, encryptionService)

  // State
  const [completed, setCompleted] = useState<boolean>(false)
  const [addEmail, setAddEmail] = useState<boolean>(false)
  const [addPhone, setAddPhone] = useState<boolean>(false)

  useEffect(() => {
    setCompleted(milestone?.completed)
  }, [milestone])

  const handleCompletion = async (checked: boolean) => {
    setCompleted(checked)
    milestonesFirestore.updateMilestoneCompletion(milestone.ref, checked)
  }

  const handleEmailAction = async (contact: any, emailOverride?: string) => {
    let note = await MilestonesApi.getMilestoneNote(milestone)
    let mailTitle = note
    let mailBody = note

    if (emailOverride || (contact.emailStrings?.length > 0 && contact.emailStrings[0])) {
      window.location.href = `mailto:${emailOverride ?? contact.emailStrings[0]}?subject=${mailTitle}&body=${encodeURIComponent(mailBody)}`
    } else {
      setAddEmail(true)
    }
  }

  const handleSmsAction = async (contact: any, telOverride?: string) => {
    let note = await MilestonesApi.getMilestoneNote(milestone)
    let body = note

    if (telOverride || (contact.phoneStrings?.length > 0 && contact.phoneStrings[0])) {
      window.location.href = `sms:${telOverride ?? contact.phoneStrings[0]}?body=${encodeURIComponent(body)}`
    } else {
      setAddPhone(true)
    }
  }

  return (
    <MDBox mb={2}>
      <AddContactDetailDialog
        type="email"
        open={addEmail}
        onClose={() => setAddEmail(false)}
        onContinue={async email => {
          contactsApi.updateContact({
            ref: milestone.contacts[0].ref,
            emails: [
              new ContactField({
                favorite: true,
                favoriteType: null,
                fieldType: "email",
                sourceValue: null,
                type: "work",
                value: email
              })
            ]
          })

          setAddEmail(false)
          handleEmailAction(milestone.contacts[0], email)
        }}
      />
      <AddContactDetailDialog
        type="phone"
        open={addPhone}
        onClose={() => setAddPhone(false)}
        onContinue={async phone => {
          contactsApi.updateContact({
            ref: milestone.contacts[0].ref,
            emails: [
              new ContactField({
                favorite: true,
                favoriteType: null,
                fieldType: "phone",
                sourceValue: null,
                type: "work",
                value: phone
              })
            ]
          })

          setAddPhone(false)
          handleSmsAction(milestone.contacts[0], phone)
        }}
      />
      <Paper elevation={noElevation ? 0 : 2} style={{ borderRadius: 12 }}>
        <MDBox width="100%" display="flex" flexDirection="column" bgColor={"grey-100"} p={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <MDBox lineHeight={0}>
                <MDTypography variant="h6" fontWeight="medium">
                  {parseMilestone(milestone)}
                </MDTypography>
              </MDBox>
              {milestone.contacts?.length > 0 && (
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption">Related to {milestone.contacts[0]?.name}</MDTypography>
                </MDBox>
              )}
            </Stack>
            <Switch
              checked={completed}
              onClick={e => {
                // e.preventDefault();
                e.stopPropagation()
              }}
              onChange={(e, checked) => handleCompletion(checked)}
            />
          </Stack>

          {milestone.contacts?.length > 0 && (
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <ForgeAvatarGroup contacts={milestone.contacts} />

              <ShareActions
                handleEmailAction={milestone.contacts.length > 0 ? () => handleEmailAction(milestone.contacts[0]) : null}
                handleSmsAction={milestone.contacts.length > 0 ? () => handleSmsAction(milestone.contacts[0]) : null}
                emailActionBackground={milestone.contacts.length > 0 && milestone.contacts[0].emailStrings?.length > 0 ? undefined : "lightgrey"}
                smsActionBackground={milestone.contacts.length > 0 && milestone.contacts[0].emailStrings?.length > 0 ? undefined : "lightgrey"}
              />
            </MDBox>
          )}
        </MDBox>
      </Paper>
    </MDBox>
  )
}

// Setting default values for the props of MilestoneCard
MilestoneCard.defaultProps = {
  milestone: null
}

export default MilestoneCard
