import { createContext, useContext, useState, useEffect, useRef } from "react"
import { AuthContext, ContactsContext } from "context"
import { Commitment } from "types/commitment"
import CommitmentsFirestore from "forge/commitments/services/firestore"

interface CommitmentsContextType {
  commitments: Commitment[]
  pastDueCommitments: Commitment[]
}

export const CommitmentsContext = createContext<CommitmentsContextType>({
  commitments: [],
  pastDueCommitments: []
})

export const CommitmentsContextProvider = ({
  contact,
  opportunityId,
  children,
  isContactPage = false
}: {
  contact?: any
  opportunityId?: string
  children: any
  isContactPage?: boolean
}) => {
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { contacts, getContacts } = useContext(ContactsContext)
  const contactsContextRef = useRef(getContacts) // Ref to hold the context value

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const commitmentsFirestoreService = new CommitmentsFirestore(user, userProfileData, encryptionService)

  // State
  const [commitments, setCommitments] = useState<Commitment[]>([])
  const [personalCommitments, setPersonalCommitments] = useState<Commitment[]>([])
  const [organizationCommitments, setOrganizationCommitments] = useState<Commitment[]>([])
  const [pastDueCommitments, setPastDueCommitments] = useState<Commitment[]>([])

  useEffect(() => {
    setCommitments([...personalCommitments, ...organizationCommitments])
  }, [personalCommitments, organizationCommitments])

  useEffect(() => {
    setPersonalCommitments([])
    if (isContactPage && !contact?.ref) return
    const unsubscribe = commitmentsFirestoreService.getPersonalCommitmentsLive(
      newCommitments => {
        setPersonalCommitments(newCommitments)
      },
      contact?.ref,
      opportunityId
    )

    return () => unsubscribe()
  }, [isEncryptionInitialized, contact?.ref, isContactPage])

  useEffect(() => {
    if (userProfileData?.doesUserBelongsToAnOrganization) {
      setOrganizationCommitments([])
      const unsubscribe = commitmentsFirestoreService.getOrganizationCommitmentsLive(
        newCommitments => {
          setOrganizationCommitments(newCommitments)
        },
        contact?.ref,
        opportunityId
      )

      return () => unsubscribe()
    } else {
      setOrganizationCommitments([])
    }
  }, [contact?.ref, userProfileData?.organization?.id, userProfileData?.organization?.pendingMigration])

  useEffect(() => {
    contactsContextRef.current = getContacts // Update the ref whenever the context value changes
    setPersonalCommitments(matchContacts(personalCommitments))
    setOrganizationCommitments(matchContacts(organizationCommitments))
  }, [contacts, personalCommitments, organizationCommitments])

  useEffect(() => {
    setPastDueCommitments(commitments.filter(e => e.startDate.toDate() < new Date()))
  }, [commitments])

  const matchContacts = (commitments: Commitment[]): Commitment[] => {
    for (let commitment of commitments) {
      commitment.contacts = contactsContextRef.current(commitment.contactRefs.map(e => e.id))
    }

    return commitments
  }

  return <CommitmentsContext.Provider value={{ commitments, pastDueCommitments }}>{children}</CommitmentsContext.Provider>
}
