import { createContext, useState, useEffect, useContext, useRef } from "react"
import { AuthContext, ContactsContext } from "context"
import { ForgeOpportunity, opportunityTeamRoles } from "types/pipeline/opportunity"
import PipelineFirestoreService from "./firestore"
import PipelineApi from "./api"
import { OpportunitiesContext } from "./OpportunitiesContext"

interface OpportunityContextType {
  opportunity: ForgeOpportunity | null
  loading: boolean
  newRelationships: { [key: string]: string[] }
  droppedRelationships: { [key: string]: string[] }
  updateOpportunity: (newOpportunity: ForgeOpportunity) => Promise<void>
  setClientTeamRelationship: (client: any, team: any) => Promise<void>
  addTeamContact: (contact: any) => Promise<void>
  addClientContact: (contact: any) => Promise<void>
  removeTeamContact: (contact: any) => Promise<string | boolean>
  removeClientContact: (contact: any) => Promise<void>
  generateTeamNotification: () => string
  setContactRole: (contact: any, role: string, initialRole?: string | null) => Promise<boolean>
}

export const OpportunityContext = createContext<OpportunityContextType>({
  opportunity: null,
  loading: false,
  newRelationships: {},
  droppedRelationships: {},
  updateOpportunity: async (newOpportunity: ForgeOpportunity) => {},
  setClientTeamRelationship: async (client: any, team: any) => {},
  addTeamContact: async (contact: any) => {},
  addClientContact: async (contact: any) => {},
  removeTeamContact: async (contact: any) => false,
  removeClientContact: async (contact: any) => {},
  generateTeamNotification: () => "",
  setContactRole: async () => false
})

export const OpportunityContextProvider = ({ opportunityId, children }: { opportunityId: string; children: any }) => {
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { contacts, getContacts } = useContext(ContactsContext)
  const { getOpportunity } = useContext(OpportunitiesContext)
  const contactsContextRef = useRef(getContacts) // Ref to hold the context value

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const pipelineFirestoreService = new PipelineFirestoreService(user, userProfileData, encryptionService)
  const pipelineApi = new PipelineApi(user, userProfileData, encryptionService)

  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [opportunity, setOpportunity] = useState<ForgeOpportunity | null>()

  // const [previousRelations, setPreviousRelations] = useState<{ [key: string]: string[] }>();
  let previousRelations: { [key: string]: string[] }
  const [newRelationships, setNewRelationships] = useState<{ [key: string]: string[] }>({})
  const [droppedRelationships, setDroppedRelationships] = useState<{ [key: string]: string[] }>({})
  // let newRelationships : { [key: string]: string[] } = {};
  // let droppedRelationships : { [key: string]: string[] } = {};

  useEffect(() => {
    ;(async () => {
      if (user) {
        setOpportunity(matchContacts(getOpportunity(opportunityId)))

        let opportunityRefPath = `opportunities/${opportunityId}`
        if (userProfileData?.doesUserBelongsToAnOrganization) {
          opportunityRefPath = `organizations/${userProfileData.organization?.id}/opportunities/${opportunityId}`
        }

        const unsubscribe = pipelineFirestoreService.getOpportunity(opportunityRefPath, newOpportunity => {
          setOpportunity(matchContacts(newOpportunity))
          if (!previousRelations) {
            previousRelations = newOpportunity.teamClientRelations
          }
          compareRelationshipState(newOpportunity.teamClientRelations)
        })

        return () => unsubscribe()
      }
    })()
  }, [isEncryptionInitialized, opportunityId])

  useEffect(() => {
    contactsContextRef.current = getContacts // Update the ref whenever the context value changes
    setOpportunity(matchContacts(opportunity))
  }, [contacts])

  const matchContacts = (opportunity: ForgeOpportunity): ForgeOpportunity => {
    if (opportunity) {
      opportunity.teamContacts = contactsContextRef.current(opportunity.teamContactsIds)
      opportunity.clientContacts = contactsContextRef.current(opportunity.clientContactsIds)
    }
    return opportunity
  }

  const compareRelationshipState = (currentRelations: Record<string, string[]>) => {
    const newRelationships: Record<string, string[]> = {}
    const droppedRelationships: Record<string, string[]> = {}

    // Check for new and dropped relationships
    for (const [teamMember, clients] of Object.entries(currentRelations)) {
      if (!previousRelations.hasOwnProperty(teamMember)) {
        newRelationships[teamMember] = clients
      } else {
        const previousClients: string[] = previousRelations[teamMember] || []
        const newClients: string[] = clients
        const addedClients: string[] = []
        const droppedClients: string[] = []

        for (const client of newClients) {
          if (!previousClients.includes(client)) {
            addedClients.push(client)
          }
        }

        for (const client of previousClients) {
          if (!newClients.includes(client)) {
            droppedClients.push(client)
          }
        }

        if (addedClients.length > 0) {
          newRelationships[teamMember] = addedClients
        }

        if (droppedClients.length > 0) {
          droppedRelationships[teamMember] = droppedClients
        }
      }
    }

    // Return new and dropped relationships
    setNewRelationships(newRelationships)
    setDroppedRelationships(droppedRelationships)
    return { newRelationships, droppedRelationships }
  }

  const setClientTeamRelationship = async (client: any, team: any) => {
    if (client?.ref != null && team?.ref != null) {
      // Remove client from all teamClientRelations values
      for (const relations of Object.values(opportunity.teamClientRelations)) {
        const index = relations.indexOf(client.ref!.id)
        if (index !== -1) {
          relations.splice(index, 1)
        }
      }

      // Add client to the teamClientRelations for the specific team member
      const teamId = team.ref!.id
      if (opportunity.teamClientRelations.hasOwnProperty(teamId)) {
        opportunity.teamClientRelations[teamId].push(client.ref!.id)
      } else {
        opportunity.teamClientRelations[teamId] = [client.ref!.id]
      }

      // Update
      await pipelineApi.updateOpportunity(opportunity)
    }
  }

  const addTeamContact = async (contact: any): Promise<void> => {
    if (contact.ref && !opportunity.teamContactsIds.includes(contact.ref.id) && !opportunity.clientContactsIds.includes(contact.ref.id)) {
      // Add Role
      if (opportunity.teamContacts.length === 0) {
        opportunity.contactsRoles[contact.ref.id] = opportunityTeamRoles[0]

        // Add Relationships
        opportunity.teamClientRelations[contact.ref.id] = opportunity.clientContacts
          .filter(e => e.ref)
          .map(e => e.ref!.id) // Assuming ref is not null
          .filter(e => e) // Remove null values
          .map(e => e as string) // Convert to string
      } else {
        opportunity.contactsRoles[contact.ref.id] = opportunityTeamRoles[1]
      }

      // Add Contact
      opportunity.teamContactsIds.push(contact.ref.id)
      await pipelineApi.updateOpportunity(opportunity)
    }
  }

  const addClientContact = async (contact: any): Promise<void> => {
    if (contact.ref && !opportunity.teamContactsIds.includes(contact.ref.id) && !opportunity.clientContactsIds.includes(contact.ref.id)) {
      await pipelineApi.addContactToOpportunity(opportunity, contact)
    }
  }

  const removeTeamContact = async (contact: any): Promise<string | boolean> => {
    if (contact.ref && opportunity.teamContactsIds.includes(contact.ref.id)) {
      if (opportunity.contactsRoles[contact.ref.id] === "Opportunity Owner") {
        // There has to be at least one Opportunity Owner
        return "One member of ‘My Team’ must be designated as the Opportunity Owner"
      }

      // Move existing Relationships to Opportunity Owner
      let opportunityOwnerIds = Object.entries(opportunity.contactsRoles)
        ?.filter(([key, value]) => value === "Opportunity Owner")
        .map(([key, _]) => key)

      let opportunityOwner =
        opportunityOwnerIds && opportunityOwnerIds.length > 0
          ? opportunity.teamContacts.find((contact: any) => opportunityOwnerIds.includes(contact.id))
          : undefined

      if (opportunityOwner?.ref) {
        if (opportunityOwner.ref.id === contact.ref.id) {
          // If Team Contact to remove is Opportunity Owner
          delete opportunity.teamClientRelations[contact.ref.id]
        } else {
          if (opportunity.teamClientRelations.hasOwnProperty(opportunityOwner.ref.id)) {
            opportunity.teamClientRelations[opportunityOwner.ref.id].push(...(opportunity.teamClientRelations[contact.ref.id] ?? []))
          } else {
            opportunity.teamClientRelations[opportunityOwner.ref.id] = opportunity.teamClientRelations[contact.ref.id] ?? []
          }
        }
      }

      // Remove
      opportunity.teamContactsIds.splice(opportunity.teamContactsIds.indexOf(contact.ref.id), 1)
      opportunity.teamContacts.splice(opportunity.teamContacts.indexOf(contact), 1)
      delete opportunity.teamClientRelations[contact.ref.id]

      // Update
      await pipelineApi.updateOpportunity(opportunity)
      return true
    }
    return false
  }

  const removeClientContact = async (contact: any): Promise<void> => {
    if (contact.ref && opportunity.clientContactsIds.includes(contact.ref.id)) {
      opportunity.clientContacts.splice(opportunity.clientContacts.indexOf(contact), 1)
      await pipelineApi.removeContactFromOpportunity(opportunity, contact)
    }
  }

  const updateOpportunity = async (newOpportunity: ForgeOpportunity) => {
    await pipelineApi.updateOpportunity(newOpportunity)
  }

  const generateTeamNotification = (): string => {
    const text: string[] = []
    text.push(
      `Hi${
        opportunity.teamContacts.length > 1 ? " everyone" : opportunity.teamContacts.length > 0 ? ` ${opportunity.teamContacts[0].firstName}` : ""
      },`
    )
    text.push(
      `I’ve recently reassigned client engagement roles in the active ${opportunity.properties?.name} opportunity${
        opportunity.properties?.company != null ? ` for ${opportunity.properties?.company.name}` : ""
      }. Please note these changed responsibilities:`
    )
    text.push("")

    let textIndex = 1
    for (const teamMember of opportunity.teamContacts) {
      const tmpText: string[] = []
      let addContactsInfoToBody = false

      tmpText.push(`\n${textIndex}. Team member: ${teamMember?.firstName} ${teamMember?.lastName}`)

      // Check for new relationships
      if (Object.keys(newRelationships).length > 0) {
        tmpText.push("    a. New Relationships:")

        const teamMemberRelationships = newRelationships[teamMember.ref?.id] || []
        if (teamMemberRelationships.length > 0) {
          for (const relationship of teamMemberRelationships) {
            const clientName = opportunity.clientContacts.find(e => e.ref?.id === relationship)
            tmpText.push(`        + ${clientName?.firstName} ${clientName?.lastName}`)
            addContactsInfoToBody = true
          }
        } else {
          tmpText.push("        - None")
        }
      }

      // Check for dropped relationships
      if (Object.keys(droppedRelationships).length > 0) {
        tmpText.push("    b. Removed Relationships:")

        const teamMemberRelationships = droppedRelationships[teamMember.ref?.id] || []
        if (teamMemberRelationships.length > 0) {
          for (const relationship of teamMemberRelationships) {
            const clientName = opportunity.clientContacts.find(e => e.ref?.id === relationship)
            tmpText.push(`        - ${clientName?.firstName} ${clientName?.lastName}`)
            addContactsInfoToBody = true
          }
        } else {
          tmpText.push("        - None")
        }
      }

      if (addContactsInfoToBody) {
        textIndex++
        text.push(tmpText.join("\n"))
      }
    }

    text.push("")
    text.push("Please let me know if you have any questions.")
    text.push("")
    text.push(`Best,\n${userProfileData?.firstName}`)

    console.log(text.join("\n"))
    return text.join("\n")
  }

  const setContactRole = async (contact: any, role: string, initialRole?: string | null): Promise<boolean> => {
    if (contact.ref) {
      // Check if there's another Opportunity Owner on the Opportunity
      if (initialRole === "Opportunity Owner") {
        const index = Object.entries(opportunity.contactsRoles).findIndex(([userId, role]) => {
          // Role is Opportunity Owner, Contact is not the current one and is
          // present in the user's contacts list
          return role === initialRole && opportunity.teamContacts.some(e => e.ref?.id === userId && e.ref?.id !== contact.ref!.id)
        })

        // There has to be at least one Opportunity Owner
        if (index === -1) {
          // return { kind: 'left', value: "One member of ‘My Team’ must be designated as the Opportunity Owner" };
          return false
        }
      }

      // Remove other Opportunity Owners
      if (role === "Opportunity Owner") {
        opportunity.contactsRoles = Object.fromEntries(
          Object.entries(opportunity.contactsRoles).map(([key, value]) => (value === "Opportunity Owner" ? ["Proposal Team", value] : [key, value]))
        )
      }

      opportunity.contactsRoles[contact.ref.id] = role

      // Update
      await pipelineApi.updateOpportunity(opportunity)

      return true
    }

    return false
  }

  return (
    <OpportunityContext.Provider
      value={{
        opportunity,
        loading,
        newRelationships,
        droppedRelationships,
        updateOpportunity,
        setClientTeamRelationship,
        addTeamContact,
        addClientContact,
        removeTeamContact,
        removeClientContact,
        generateTeamNotification,
        setContactRole
      }}
    >
      {children}
    </OpportunityContext.Provider>
  )
}
