// @mui material components
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Material Dashboard 2 PRO React TS components
import CrmTile from "./CrmTile"
import { Crm } from "types/pipeline/crm"
import { useContext, useState } from "react"
import { CrmContext } from "forge/settings/services/CrmContext"
import { AuthContext } from "context"
import { Backdrop } from "@mui/material"
import BillingDialog from "../SettingsBilling/BillingDialog"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { isEmpty } from "lodash"

function SettingsCrm(): JSX.Element {
  // State
  const [openBillingDialog, setOpenBillingDialog] = useState(false)

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { pipelineConfig, loading, syncingCrm, createConnection, deleteConnection } = useContext(CrmContext)
  const { organization } = useContext(OrganizationContext)

  // Services
  const { userProfileData } = getCurrentUser()

  const onHubspotSetupTapped = async () => {
    console.log("onHubspotSetupTapped")
    if (userProfileData.hasAnActiveSubscription) {
      createConnection(Crm.hubspot)
    } else {
      setOpenBillingDialog(true)
    }
  }

  const onSalesforceSetupTapped = () => {
    console.log("onSalesforceSetupTapped")
    if (userProfileData.hasAnActiveSubscription) {
      createConnection(Crm.salesforce)
    } else {
      setOpenBillingDialog(true)
    }
  }

  const onRemoveTapped = () => {
    deleteConnection(pipelineConfig?.integration)
  }

  const doesUserBelongsToAnOrganization = userProfileData?.doesUserBelongsToAnOrganization
  const doesOrganizationHasCrmText = !isEmpty(organization?.pipelineConfig?.crmId)
    ? "Review the CRM connection"
    : "Sync the organization’s CRM platform by clicking"

  return (
    <Card id="crm">
      <BillingDialog openDialog={openBillingDialog} handleCloseDialog={() => setOpenBillingDialog(false)} />
      <Backdrop
        sx={{
          background: "rgba(255, 255, 255, 0.85)",
          zIndex: theme => 999,
          position: "absolute",
          borderRadius: "16px",
          overflow: "hidden"
        }}
        open={doesUserBelongsToAnOrganization}
      >
        <MDTypography variant="button" color="text">
          It appears you work with an organization. {doesOrganizationHasCrmText} <a href="/organization#crm">here</a>.
        </MDTypography>
      </Backdrop>
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">CRM</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can setup and manage any external CRM integration. Only one CRM integration can be active at a time.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <CrmTile
          crm={Crm.hubspot}
          enabled={!doesUserBelongsToAnOrganization && pipelineConfig?.isEnabled && pipelineConfig?.integration === Crm.hubspot}
          loading={loading && syncingCrm === Crm.hubspot}
          disabledText={
            !doesUserBelongsToAnOrganization &&
            pipelineConfig?.isEnabled &&
            pipelineConfig?.integration &&
            pipelineConfig?.integration === Crm.salesforce
              ? "Salesforce integration is enabled"
              : undefined
          }
          onSetupTapped={
            pipelineConfig?.isEnabled && pipelineConfig?.integration && pipelineConfig?.integration === Crm.salesforce
              ? undefined
              : onHubspotSetupTapped
          }
          onRemoveTapped={onRemoveTapped}
        />
        <CrmTile
          crm={Crm.salesforce}
          enabled={!doesUserBelongsToAnOrganization && pipelineConfig?.isEnabled && pipelineConfig?.integration === Crm.salesforce}
          loading={loading && syncingCrm === Crm.salesforce}
          disabledText={
            !doesUserBelongsToAnOrganization &&
            pipelineConfig?.isEnabled &&
            pipelineConfig?.integration &&
            pipelineConfig?.integration === Crm.hubspot
              ? "Hubspot integration is enabled"
              : undefined
          }
          onSetupTapped={
            pipelineConfig?.isEnabled && pipelineConfig?.integration && pipelineConfig?.integration === Crm.hubspot
              ? undefined
              : onSalesforceSetupTapped
          }
          onRemoveTapped={onRemoveTapped}
        />
      </MDBox>
    </Card>
  )
}

export default SettingsCrm
