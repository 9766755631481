import React from "react"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import { News } from "types/news/news"

interface Props {
  article: News
  openDialogNews: boolean
  setOpenDialogNews: (status: boolean) => void
}

const ConfirmationLeaveForgeDialog: React.FC<Props> = ({ article, openDialogNews, setOpenDialogNews }) => {
  return (
    <ConfirmationDialog
      openDialog={openDialogNews}
      handleCloseDialog={result => {
        setOpenDialogNews(false)
        if (result) window.open(article.url, "_blank")
      }}
      title="Leaving Forge"
      description="Caution: you are being redirected to a third party news site. Forge is not responsible for the content of any third party site."
      confirmText="I Understand"
      denyText="Go back"
      denyVariant="outlined"
    />
  )
}

export default ConfirmationLeaveForgeDialog
