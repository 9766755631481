import { capitalize } from "@mui/material"
import MDTypography from "components/MDTypography"

// Regular expression to find URLs in the text
const urlPattern = /(\bhttps?:\/\/[^\s]+)/g

const splitAsCharactersForRegex = (text: string) => text?.split("").join("[\\W_]?").replaceAll(/\s+/g, "")

const checkBasedOnRegex = (entity: string, test: string) => {
  const entityRegexStyle = splitAsCharactersForRegex(entity)
  const entityRegex = new RegExp("\\b" + entityRegexStyle + "\\b", "gi")
  const testRegexStyle = splitAsCharactersForRegex(test)
  const testRegex = new RegExp("\\b" + testRegexStyle + "\\b", "gi")

  return entityRegex.test(test) || testRegex.test(entity)
}

export const parseKnowledge = (knowledge: any, answer?: string, defaultFontWeight: string = "normal") => {
  if (!knowledge) {
    return (
      <MDTypography display="inline" variant="body2" fontWeight={defaultFontWeight}>
        {answer}
      </MDTypography>
    )
  }

  if (!(knowledge.answer || answer)) {
    return (
      <MDTypography display="inline" variant="body2" fontWeight={defaultFontWeight}>
        {answer}
      </MDTypography>
    )
  }

  // Remove Curly Braces, Parenthesis, Brackets
  let text = (answer ?? knowledge.answer)?.replace(/{|}|\[|\]|\(|\)/g, "")

  // Separate text into parts based on keywords
  let result = separateText(text, [
    ...(knowledge?.entity ?? []),
    ...(knowledge?.searchTerm ?? []),
    ...(knowledge.subjects ?? []),
    ...(knowledge.milestone?.triggerTexts ?? [])
  ])

  return result.map<JSX.Element>((part: string) => {
    if (
      knowledge.entity &&
      knowledge?.entity?.some(
        (entity: string) => entity.toLowerCase() === part.toLowerCase() || checkBasedOnRegex(entity.toLowerCase(), part.toLowerCase())
      )
    ) {
      return (
        <MDTypography
          display="inline"
          variant="body2"
          fontWeight="bold"
          style={{ color: "cornflowerblue", textDecoration: "underline", cursor: "pointer" }}
        >
          {part}
        </MDTypography>
      )
    }

    if (
      knowledge.searchTerm &&
      knowledge?.searchTerm?.some(
        (searchTerm: string) => searchTerm.toLowerCase() === part.toLowerCase() || checkBasedOnRegex(searchTerm.toLowerCase(), part.toLowerCase())
      )
    ) {
      return (
        <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "cornflowerblue" }}>
          {part}
        </MDTypography>
      )
    }

    if (knowledge.subjects && knowledge.subjects?.some((subject: string) => subject.toLowerCase() === part.toLowerCase())) {
      return (
        <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "orange" }}>
          {capitalize(part)}
        </MDTypography>
      )
    }

    if (
      knowledge.milestone?.triggerTexts &&
      knowledge.milestone?.triggerTexts?.some((triggerText: string) => triggerText.toLowerCase() === part.toLowerCase())
    ) {
      return (
        <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "green" }}>
          {capitalize(part)}
        </MDTypography>
      )
    }

    // Replace the URL in the string with an anchor tag
    const linkedText = part.replace(urlPattern, '<a href="$1" target="_blank">$1</a>')
    return (
      <MDTypography
        display="inline"
        variant="body2"
        fontWeight={defaultFontWeight}
        dangerouslySetInnerHTML={{
          __html: linkedText
        }}
      ></MDTypography>
    )
  })
}

export const parseMilestone = (milestone: any) => {
  if (!milestone.name) {
    return null
  }

  // Remove Curly Braces
  let text = milestone.name.replace(/{|}/g, "").replace(/\[\[|\]\]|\[\(|\)\]/g, "")

  // Separate text into parts based on keywords
  let result = separateText(text, [milestone.subject, ...(milestone?.triggerTexts ?? [])])

  return result.map<JSX.Element>((part: string) => {
    if (milestone.subject && milestone.subject === part) {
      return (
        <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "orange" }}>
          {capitalize(part)}
        </MDTypography>
      )
    }

    if (milestone?.triggerTexts && milestone?.triggerTexts.includes(part)) {
      return (
        <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "green" }}>
          {capitalize(part)}
        </MDTypography>
      )
    }

    return (
      <MDTypography display="inline" variant="body2">
        {part}
      </MDTypography>
    )
  })
}

function separateText(text: string, keywords: string[]) {
  // Initialize an array to store the separated parts
  let parts = []

  // Remove commas at the end
  text = text.replace(/,(?=\s*$)/g, "")

  // Remove empty strings from keywords
  keywords = keywords.filter(item => item !== "")

  if (text) {
    // Initialize a variable to store the current part
    let currentPart = ""

    // Iterate through each character in the text
    for (let i = 0; i < text.length; i++) {
      const char = text[i]
      const nextChar = text[i + 1] || ""

      // Add the current character to the current part
      currentPart += char

      // Check if the current part contains any of the keywords
      const matchedKeyword = keywords.find(keyword => {
        const keywordRegex = splitAsCharactersForRegex(keyword)
        const regex = new RegExp("\\b" + keywordRegex + "\\b", "gi")
        return regex.test(currentPart + nextChar)
      })

      // If the current part contains a keyword, add it to the parts array
      if (matchedKeyword) {
        try {
          const keywordRegex = splitAsCharactersForRegex(matchedKeyword)
          const regExp = new RegExp(`\\b${keywordRegex}(?:'s)?\\b`, "gmi")
          const newUrlRegex = new RegExp(urlPattern)

          const regexValidation = regExp.test(currentPart)
          const urlValidation = newUrlRegex.test(currentPart)

          if (urlValidation && nextChar === " ") {
            currentPart.replace(newUrlRegex, match => {
              parts.push(currentPart.slice(0, currentPart.indexOf(match)))
              parts.push(match)
              return ""
            })
            currentPart = ""
          }

          if (regexValidation && !urlValidation) {
            currentPart.replace(regExp, match => {
              parts.push(currentPart.slice(0, currentPart.indexOf(match)))
              parts.push(match)
              return ""
            })
            currentPart = ""
          }
        } catch (error) {
          console.warn(error)
        }
      }
    }

    // Add the remaining part to the parts array if not empty
    if (currentPart.trim() !== "") {
      parts.push(currentPart)
    }
  }

  return parts
}
