import { ForumOutlined, Language, LinkedIn, LocationOn, MailOutline, SmartphoneOutlined } from "@mui/icons-material"
import { Card, Dialog, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import ContactHeaderHotButton from "../ContactHeaderHotButton"
import ContactHeaderMenu from "../ContactHeaderMenu"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { useContext, useEffect, useState } from "react"
import BackButton from "forge/core/components/BackButton"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { MembersContext } from "forge/organization/members/services/MembersContext"
import { DocumentReference } from "firebase/firestore"
import MemberTile from "forge/organization/members/components/MemberTile"
import telephoneWhite from "assets/forge/svgs/telephone_white.svg"
import telephoneBlack from "assets/forge/svgs/telephone_black.svg"
import { getPhonesByType } from "types/contact/contact"
import { getEmailDomain, isUrlValid, validateIfNotCommonDomain } from "../../utils"
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext"

function ContactHeaderCard({ contact, isContactProfilePage = true }: { contact: any; isContactProfilePage?: boolean }) {
  // Context
  const { getMember } = useContext(MembersContext)
  const { commonEmailDomains } = useContext(RemoteConfigContext)

  // State
  const [open, setOpen] = useState(false)
  const [isOrgContact, setIsOrganizationContact] = useState(false)
  const [workPhone, setWorkPhone] = useState(() => getPhonesByType(contact, "work"))
  const [mobilePhone, setMobilePhone] = useState(() => getPhonesByType(contact, "mobile"))
  const [isLinkedInUrlValid, setIsLinkedInUrlValid] = useState<boolean>(false)
  const [linkedInUrl, setLinkedInUrl] = useState<string>()
  const [isCompanyEmail, setIsCompanyEmail] = useState<boolean>(false)
  const [companyWebsite, setCompanyWebsite] = useState<string>()
  const [creatorMember, setMemberCreator] = useState<ForgeOrganizationMember>()

  const onAvatarClicked = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return setOpen(true)
  }
  const handleCloseDialog = () => setOpen(false)

  useEffect(() => {
    if (contact?.isOrganizationContact && contact?.createdBy && contact.createdBy instanceof DocumentReference) {
      setIsOrganizationContact(true)
      setMemberCreator(getMember(contact.createdBy.id))
    }

    if (contact) {
      setWorkPhone(getPhonesByType(contact, "work"))
      setMobilePhone(getPhonesByType(contact, "mobile"))

      const isValid = isUrlValid(contact?.linkedInUrl)
      setIsLinkedInUrlValid(isValid)
      setLinkedInUrl(isValid && contact?.linkedInUrl)

      // Website
      const contactEmail = contact?.emailStrings?.[0]
      if (contactEmail) {
        const isNotCommonDomain = validateIfNotCommonDomain(contactEmail, commonEmailDomains)
        setIsCompanyEmail(isNotCommonDomain)
        if (isNotCommonDomain) {
          const fullDomain = "https://" + getEmailDomain(contactEmail)
          setCompanyWebsite(fullDomain)
        }
      }
    }
  }, [contact])

  return (
    <Card
      sx={{
        background: "black"
      }}
    >
      <Stack direction="row" useFlexGap flexWrap="wrap" m={3} spacing={2} justifyContent="space-between">
        <Dialog
          open={open && contact?.linkedInProfileData?.profile_pic_url}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              color: "white",
              backgroundColor: "black",
              boxShadow: "none"
            },
            sx: { borderRadius: "16px" }
          }}
        >
          <img src={contact?.linkedInProfileData?.profile_pic_url} alt={contact?.name} />
        </Dialog>
        <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}>
          {isContactProfilePage && <BackButton color="white" />}
          <ForgeContactAvatar contact={contact} diameter={100} warmthRingThickness={5} onContactTapped={onAvatarClicked} />
          <MDBox>
            <MDTypography variant="h4" fontWeight="bold" color="white">
              {contact?.title} {contact?.name}
            </MDTypography>
            <MDTypography color="white">
              {contact?.jobTitle}
              {contact?.jobTitle && contact?.company && " | "}
              {contact?.company}
            </MDTypography>
            <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center" spacing={2} mt={1}>
              <ContactHeaderHotButton
                enabled={contact?.emailStrings?.[0]}
                onClick={() => {
                  if (!contact?.emailStrings?.[0]) return
                  window.location.href = `mailto:${contact?.emailStrings?.[0]}`
                }}
              >
                <MailOutline />
              </ContactHeaderHotButton>
              <ContactHeaderHotButton
                enabled={!!workPhone}
                onClick={() => {
                  if (!workPhone) return
                  window.location.href = `tel:${workPhone?.value}`
                }}
              >
                <img loading="lazy" width="20" src={!!workPhone ? telephoneBlack : telephoneWhite} alt="" />
              </ContactHeaderHotButton>
              <ContactHeaderHotButton
                enabled={!!mobilePhone}
                onClick={() => {
                  if (!mobilePhone) return
                  window.location.href = `tel:${mobilePhone?.value}`
                }}
              >
                <SmartphoneOutlined />
              </ContactHeaderHotButton>
              <ContactHeaderHotButton
                enabled={!!mobilePhone}
                onClick={() => {
                  if (!mobilePhone) return
                  window.location.href = `sms:${mobilePhone?.value}`
                }}
              >
                <ForumOutlined />
              </ContactHeaderHotButton>
              <ContactHeaderHotButton
                enabled={contact?.addresses?.[0]?.value}
                onClick={() => {
                  if (!contact?.addresses?.[0]?.value) return
                  window.open(`https://maps.google.com/?q=${contact?.addresses?.[0]?.value}`, "_blank")
                }}
              >
                <LocationOn />
              </ContactHeaderHotButton>
              <ContactHeaderHotButton
                enabled={isLinkedInUrlValid}
                onClick={() => {
                  if (!isLinkedInUrlValid) return
                  window.open(linkedInUrl, "_blank")
                }}
              >
                <LinkedIn />
              </ContactHeaderHotButton>
              <ContactHeaderHotButton
                enabled={isCompanyEmail}
                onClick={() => {
                  if (!isCompanyEmail) return
                  window.open(companyWebsite, "_blank")
                }}
              >
                <Language />
              </ContactHeaderHotButton>
            </Stack>
          </MDBox>
        </Stack>
        <Stack direction="column">
          {contact?.status === "live" && <ContactHeaderMenu contact={contact} isOrganizationContact={isOrgContact} />}
          {creatorMember && <MemberTile member={creatorMember} color="white" />}
        </Stack>
      </Stack>
    </Card>
  )
}

export default ContactHeaderCard
