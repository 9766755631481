import React, { useEffect } from "react"

/**
 *
 * @param param0
 */
const useAsyncFallbackAction = ({ hookDependencies, actionFallback }: { hookDependencies: boolean[]; actionFallback: () => void }) => {
  useEffect(() => {
    actionFallback()
  }, [...hookDependencies])
}

export default useAsyncFallbackAction
