import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getRemoteConfig } from "firebase/remote-config"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { auth } from "firebaseui"
import forgeImage from "./assets/forge/images/forge_favicon.png"

const firebaseConfig = {
  apiKey: "AIzaSyAO8Hl-I85RYytU-X2F6rrQj0zocRAqdWk",
  authDomain: "forge-development.firebaseapp.com",
  databaseURL: "https://forge-development-default-rtdb.firebaseio.com",
  projectId: "forge-development",
  storageBucket: "forge-development.appspot.com",
  messagingSenderId: "514739754269",
  appId: "1:514739754269:web:71d6303a562fadb2d93df4",
  measurementId: "G-NMES2KM87G"
}

// Firebase App
const app = initializeApp(firebaseConfig)

// Firebase App Check
if (process.env.REACT_APP_DEV === "true") {
  // eslint-disable-next-line
  ;(self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider("6Lc0esQoAAAAAGPUXJRIWS5f4WrHogPaZ2Do_opI"),
//   isTokenAutoRefreshEnabled: true,
// });

export const firebaseAuthUi = new auth.AuthUI(getAuth())
export const firestoreDb = getFirestore(app)
export const remoteConfig = getRemoteConfig(app)
export const firebaseMessaging = getMessaging(app)
// export const firebaseAppCheck = appCheck;

export const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission()

    if (permission === "granted") {
      console.log("Notification permission granted.")
      // Get the FCM token
      const token = await getToken(firebaseMessaging)
      return token
    } else {
      console.log("Notification permission denied.")
    }
  } catch (error) {
    console.error("Error setting up notifications:", error)
  }
}

const notify = (notification: any) => {
  const newNotification = new Notification(notification.title, {
    body: notification.body,
    data: {
      url: notification.url + "/" + notification.page || ""
    },
    icon: forgeImage
  })

  newNotification.onclick = event => {
    event.preventDefault() // prevent the browser from focusing the Notification's tab
    window.open(notification.url + "/" + notification.page || "", "_blank")
  }
}

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(firebaseMessaging, payload => {
      if (payload.data?.event?.includes("events")) {
        const page = payload.data.event.split("events")
        notify({ ...payload.notification, url: window.location.origin.toString(), page: `calendar/events${page[page.length - 1]}` })
      } else notify({ ...payload.notification, url: window.location.origin.toString() })
      resolve(payload)
    })
  })
