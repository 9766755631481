import zIndex from "@mui/material/styles/zIndex"
import * as Sentry from "@sentry/react"
import { rgb } from "chroma-js"

export const initSentry = () => {
  Sentry.init({
    dsn: "https://7d8442f51f5a2fbbbb364d67cfea4eac@o4508489730162688.ingest.us.sentry.io/4508489789472768",
    environment: process.env.REACT_APP_DEV === "true" ? "development" : "production",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "system",
        triggerLabel: "Contact Support",
        formTitle: "Contact Support",
        submitButtonLabel: "Send",
        messagePlaceholder: "What's the feedback? What did you expect?"

        // Check globals.ts -> #sentry-feedback
        // Setting the values here has no effect
        // accentBackground: "#3D79EE",
        // zIndex: 1000,
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app\.forgeyourcrm\.com/, /^https:\/\/forge\-development\.uc\.r\.appspot\.com/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
