import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import HttpService from "forge/core/services/http.service"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { ForgeEncryption } from "forge/core/services/encryption"
import { Knowledge } from "../schemas/knowledge"
import { ForgeOrganization } from "forge/organization/types/organization"

class KnowledgeApi {
  private user: User
  private userProfileData: UserProfileData
  private organization: ForgeOrganization
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, organization: ForgeOrganization, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.organization = organization
    this.encryptionService = encryptionService
  }

  analyzeKnowledgeFunc = async (answer: string, contextAnswer: string): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "knowledge-newAnswer")
    const result = await callable({
      answer: answer,
      contextAnswer: contextAnswer ?? answer
    })

    return result.data
  }

  analyzeKnowledge = async (contact: any, knowledge: Knowledge): Promise<Knowledge> => {
    const endpoint = `knowledge/analyze?contactId=${contact?.ref.id}`

    let result = await HttpService.post(endpoint, {
      knowledge: await knowledge.toMap({
        toFirestore: false
      })
    })

    if (Array.isArray(result) && result.length > 0) {
      return result[0]
    }

    return result ? Knowledge.fromMap(result, this.encryptionService, this.userProfileData.organization?.id) : knowledge
  }

  createKnowledge = async (contact: any, knowledge: Knowledge, encrypt = false): Promise<Knowledge> => {
    const endpoint = `knowledge?contactId=${contact?.ref.id}`

    let result = await HttpService.post(endpoint, {
      knowledge: [
        await knowledge.toMap({
          encryptionConfig: {
            encrypted: encrypt,
            encryptedForOrganization: false,
            service: this.encryptionService,
            userProfileData: this.userProfileData,
            organization: this.organization
          }
        })
      ]
    })

    if (Array.isArray(result) && result.length > 0) {
      return Knowledge.fromMap(result[0], this.encryptionService, this.userProfileData.organization?.id)
    }

    return result ? Knowledge.fromMap(result, this.encryptionService, this.userProfileData.organization?.id) : knowledge
  }

  updateKnowledge = async (contact: any, knowledge: Knowledge, encryptedForOrganization: boolean) => {
    const endpoint = `knowledge/${knowledge.ref?.id}?contactId=${contact?.ref.id}`

    return await HttpService.patch(endpoint, {
      knowledge: await knowledge.toMap({
        encryptionConfig: {
          encrypted: true,
          service: this.encryptionService,
          userProfileData: this.userProfileData,
          organization: this.organization,
          encryptedForOrganization
        }
      })
    })
  }

  updateKnowledgePrivacy = async (contact: any, knowledge: Knowledge) => {
    const endpoint = `knowledge/privacy/${knowledge.ref?.id}?contactId=${contact?.ref.id}`

    const tmp = await knowledge.toMap({
      encryptionConfig: {
        encrypted: true,
        service: this.encryptionService,
        userProfileData: this.userProfileData,
        organization: this.organization,
        encryptedForOrganization: !knowledge.isPrivate
      }
    })
    // console.log(tmp);
    return await HttpService.patch(endpoint, {
      knowledge: tmp
    })
  }

  deleteKnowledge = async (contact: any, knowledge: Knowledge) => {
    const endpoint = `knowledge/${knowledge.ref?.id}?contactId=${contact?.ref.id}`

    return await HttpService.delete(endpoint)
  }

  getKnowledgeWikiPageId = async (knowledge: Knowledge, searchTerm: any): Promise<{ [key: string]: any }> => {
    const endpoint = "knowledge/wiki"

    return await HttpService.post(endpoint, {
      knowledge: await knowledge.toMap({
        encryptionConfig: {
          encrypted: true,
          service: this.encryptionService,
          userProfileData: this.userProfileData,
          organization: this.organization
        }
      }),
      searchTerm: searchTerm
    })
  }

  getKnowledgeSummary = async (contact: any): Promise<any> => {
    if (!contact?.ref?.id)
      return {
        error: true,
        message: "No contact id provided."
      }

    const endpoint = `contacts/${contact?.ref?.id}/summary`

    return await HttpService.get(endpoint)
  }

  getListenQuestions = async (): Promise<any> => {
    const endpoint = "contacts/questions"

    return await HttpService.get(endpoint)
  }
}

export default KnowledgeApi
