import { createUseStyles } from "react-jss"

// MUI
import { Avatar, Box, Card, CardMedia, Chip, Divider, Drawer, Stack, TextField, useMediaQuery } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

// Services
import { News } from "types/news/news"
import NewsFirestoreService from "../../services/firestore"

// Constants

import bgImage from "assets/forge/images/forge.png"
import { SearchTerm } from "forge/knowledge/schemas/search-term"
import { Done, Edit, PersonAdd } from "@mui/icons-material"
import { useContext, useEffect, useState } from "react"
import { AuthContext, ContactsContext } from "context"
import { replaceDiacritics } from "forge/core/utilities"
import NewsAffectedContactCard from "../NewsAffectedContactCard"
import theme from "assets/theme"
import ConfirmationLeaveForgeDialog from "../ConfirmLeaveForge"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  avatar: {
    backgroundColor: "white",
    color: "black",
    height: "fit-content",
    width: "fit-content"
  },
  chipIcon: { color: "black", width: "0.75em", height: "0.75em", margin: "4px" }
})

const formatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true
})

function NewsDetail({ openDrawer, handleCloseDrawer, article }: { openDrawer: boolean; handleCloseDrawer: () => void; article: News }) {
  // UI
  const classes = styles()
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { contacts } = useContext(ContactsContext)

  // State
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [affectedContacts, setAffectedContacts] = useState<any[]>(article?.affectedContactsRecords)
  const [knowledgeMap, setKnowledgeMap] = useState<{ [key: string]: any[] }>({})
  const [activeSearchTerms, setActiveSearchTerms] = useState<{ [key: string]: boolean }>({})
  const [openDialogNews, setOpenDialogNews] = useState(false)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const newsFirestoreService = new NewsFirestoreService(user, userProfileData, encryptionService)

  useEffect(() => {
    setActiveSearchTerms(
      article.allSearchTerms.reduce((acc: any, key) => {
        acc[key.searchTerm] = true
        return acc
      }, {})
    )

    setAffectedContacts(article.affectedContactsRecords)
    ;(async () => {
      // Knowledge
      let newKnowledgeMap: { [key: string]: any[] } = {}
      await Promise.all(
        article.affectedContactsRecords
          .filter(e => e.ref !== null)
          .map(e =>
            newsFirestoreService
              .getNewsKnowledgeForContact(
                e.ref!.id,
                article.allSearchTerms.map(e => e.searchTerm)
              )
              .then(value => (newKnowledgeMap[e.ref?.id ?? ""] = value))
          )
      )
      setKnowledgeMap(newKnowledgeMap)
    })()
  }, [])

  useEffect(() => {
    let searchTerms = Object.keys(activeSearchTerms).filter(key => activeSearchTerms[key] ?? false)

    setAffectedContacts(
      article.affectedContactsRecords.filter(contact => {
        if (!contact.ref || !knowledgeMap[contact.ref.id]) {
          return false
        }

        return knowledgeMap[contact.ref.id].some(knowledge => {
          console.log(knowledge.answer)
          return knowledge.searchTerm.some((searchTerm: string) => {
            return searchTerms.includes(searchTerm)
          })
        })
      })
    )
  }, [activeSearchTerms, knowledgeMap])

  useEffect(() => {
    if (searchQuery.trim().length > 0) {
      // Search in the existing affected contacts for the query
      let existingContactsAffected = article.affectedContactsRecords.filter(contact => {
        let fullName: string = ""
        fullName += contact.firstName
        fullName += " "
        fullName += contact.lastName ?? ""

        fullName = replaceDiacritics(fullName)

        return fullName.toLowerCase().includes(searchQuery.toLowerCase())
      })
      setAffectedContacts(existingContactsAffected)

      // If there's existing contacts matching the query stop the process
      if (existingContactsAffected.length > 0) return

      // Search in all the contacts for the query
      let forgeContactsAffected = contacts.filter(contact => {
        let fullName: string = ""
        fullName += contact.firstName
        fullName += " "
        fullName += contact.lastName ?? ""

        fullName = replaceDiacritics(fullName)

        return fullName.toLowerCase().includes(searchQuery.toLowerCase()) && !article.affectedContactsRecords.includes(contact)
      })
      setAffectedContacts(forgeContactsAffected)
    } else {
      setAffectedContacts(article.affectedContactsRecords)
    }
  }, [searchQuery])

  const onChipTapped = (searchTerm: SearchTerm) => {
    // Make a copy of the data object
    const updatedData = { ...activeSearchTerms }

    // Toggle the value of the specified key
    updatedData[searchTerm.searchTerm] = !updatedData[searchTerm.searchTerm]

    // Set the state with the updated object
    setActiveSearchTerms(updatedData)
  }

  const onContactAdded = (contact: any, knowledge: any) => {
    // Update Affected Contacts
    article.affectedContactsRecords.push(contact)

    // Update Knowledge Map
    const updatedMap = { ...knowledgeMap }
    updatedMap[contact.ref?.id ?? ""] = [knowledge]
    setKnowledgeMap(updatedMap)

    // Update Query
    setSearchQuery("")
  }

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      {/* Header */}
      <div style={{ backgroundColor: "black" }}>
        <Box height={6} />
        <ConfirmationLeaveForgeDialog openDialogNews={openDialogNews} setOpenDialogNews={setOpenDialogNews} article={article} />
        <Card style={{ padding: 12, margin: 16, cursor: "pointer" }} elevation={3} onClick={() => setOpenDialogNews(true)}>
          <MDBox display="flex" alignItems="center">
            <MDBox>
              <CardMedia
                sx={{ height: 86, width: 86, margin: 0 }}
                style={{
                  backgroundSize: article.image ? "100%" : "70%",
                  backgroundColor: "black"
                }}
                image={article.image ?? bgImage}
                title={article.title}
              />
            </MDBox>
            <MDBox ml={2} mr={1} mt={0.5} lineHeight={1.4}>
              <MDTypography fontSize="0.7rem" variant="body2" color="gray">
                {article.source}
              </MDTypography>
              <MDTypography fontSize="0.7rem" variant="body2" color="gray">
                {formatter.format(new Date(article.publishedAt * 1000))}
              </MDTypography>
              <MDTypography
                sx={{
                  "white-space": "break-spaces"
                }}
                variant="h5"
                fontWeight="medium"
              >
                {article.title}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
        <Divider />
        <MDBox mx={3} mb={2}>
          <MDTypography
            sx={{
              "white-space": "break-spaces"
            }}
            variant="body2"
            color="light"
          >
            {article.description}
          </MDTypography>
        </MDBox>
        <Box height={8} />
      </div>

      {/* Body */}
      <MDBox mx={3} mt={2}>
        <Stack direction="row" useFlexGap flexWrap="wrap" justifyContent="center" alignItems="center" spacing={0.7}>
          {article.allSearchTerms.map((searchTerm: SearchTerm) => {
            let isActive = activeSearchTerms[searchTerm.searchTerm]
            return (
              <Chip
                label={searchTerm.searchTerm}
                style={
                  isActive
                    ? { backgroundColor: "black", color: "white" }
                    : { backgroundColor: "white", color: "black", textDecoration: "line-through", border: "1px solid black" }
                }
                onClick={() => onChipTapped(searchTerm)}
                onDelete={() => onChipTapped(searchTerm)}
                deleteIcon={
                  isActive ? (
                    <Avatar className={classes.avatar}>
                      <Done className={classes.chipIcon} />
                    </Avatar>
                  ) : (
                    <></>
                  )
                }
              />
            )
          })}
        </Stack>
        <Box height={16} />
        <TextField
          id="title"
          label="Who should see this news?"
          variant="outlined"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          style={{ flex: 1, width: "100%" }}
          InputLabelProps={{ style: { color: "gray" } }}
          InputProps={{
            startAdornment: <PersonAdd fontSize="small" />,
            endAdornment: <Edit fontSize="small" />
          }}
        />
        <Box height={16} />
        <MDTypography
          sx={{
            "text-align": "center"
          }}
          variant="h6"
          fontWeight="medium"
        >
          Contacts potentially interested in this news
        </MDTypography>
        <Box height={16} />
        <MDBox style={{ width: "100%", flex: 1 }}>
          {affectedContacts.map((contact: any) => (
            <NewsAffectedContactCard contact={contact} article={article} knowledgeMap={knowledgeMap} onContactAdded={onContactAdded} />
          ))}
        </MDBox>
      </MDBox>
    </Drawer>
  )
}

// Setting default values for the props of NewsDetail
NewsDetail.defaultProps = {
  handleCloseDrawer: null
}

export default NewsDetail
