import { Card } from "@mui/material"
import MDBox from "components/MDBox"
import MDSnackbar from "components/MDSnackbar"
import MDTypography from "components/MDTypography"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import BillingDialog from "forge/settings/components/SettingsBilling/BillingDialog"
import CrmTile from "forge/settings/components/SettingsCrm/CrmTile"
import { CrmContext } from "forge/settings/services/CrmContext"
import { useContext, useState } from "react"
import { Crm } from "types/pipeline/crm"

function OrganizationCrmSyncCard(): JSX.Element {
  // State
  const [openBillingDialog, setOpenBillingDialog] = useState(false)

  // Context
  const { organization } = useContext(OrganizationContext)
  const {
    pipelineConfig,
    organizationPipelineConfig,
    loading,
    syncingCrm,
    showNotification,
    notificationMessage,
    setShowNotification,
    createConnection,
    deleteConnection
  } = useContext(CrmContext)

  const onHubspotSetupTapped = async () => {
    console.log("onHubspotSetupTapped")
    if (organization?.hasAnActiveSubscription) {
      createConnection(Crm.hubspot, true)
    } else {
      setOpenBillingDialog(true)
    }
  }

  const onSalesforceSetupTapped = () => {
    console.log("onSalesforceSetupTapped")
    if (organization?.hasAnActiveSubscription) {
      createConnection(Crm.salesforce, true)
    } else {
      setOpenBillingDialog(true)
    }
  }

  const onRemoveTapped = () => {
    deleteConnection(organizationPipelineConfig?.integration)
  }

  return (
    <Card id="crm" style={{ marginBottom: "24px" }}>
      <BillingDialog openDialog={openBillingDialog} handleCloseDialog={() => setOpenBillingDialog(false)} />
      <MDSnackbar
        color="error"
        icon="info"
        title="Connection Result"
        content={notificationMessage}
        dateTime=""
        open={showNotification}
        onClose={() => setShowNotification(false)}
        close={() => setShowNotification(false)}
        bgWhite
      />
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Organization CRM</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can setup and manage any external CRM integration of your organization.
          <br />
          Only one CRM integration can be active at a time.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        {organizationPipelineConfig?.isEnabled ? (
          <>
            {organizationPipelineConfig?.integration === Crm.hubspot && (
              <CrmTile
                crm={Crm.hubspot}
                enabled={pipelineConfig?.isEnabled}
                loading={loading && syncingCrm === Crm.hubspot}
                disabledText={undefined}
                onSetupTapped={onHubspotSetupTapped}
                onRemoveTapped={onRemoveTapped}
              />
            )}
            {organizationPipelineConfig?.integration === Crm.salesforce && (
              <CrmTile
                crm={Crm.salesforce}
                enabled={pipelineConfig?.isEnabled}
                loading={loading && syncingCrm === Crm.salesforce}
                disabledText={undefined}
                onSetupTapped={onSalesforceSetupTapped}
                onRemoveTapped={onRemoveTapped}
              />
            )}
          </>
        ) : (
          <>
            <CrmTile
              crm={Crm.hubspot}
              enabled={organizationPipelineConfig?.isEnabled && organizationPipelineConfig?.integration === Crm.hubspot}
              loading={loading && syncingCrm === Crm.hubspot}
              disabledText={
                organizationPipelineConfig?.isEnabled &&
                organizationPipelineConfig?.integration &&
                organizationPipelineConfig?.integration === Crm.salesforce
                  ? "Salesforce integration is enabled"
                  : undefined
              }
              onSetupTapped={
                organizationPipelineConfig?.isEnabled &&
                organizationPipelineConfig?.integration &&
                organizationPipelineConfig?.integration === Crm.salesforce
                  ? undefined
                  : onHubspotSetupTapped
              }
              onRemoveTapped={onRemoveTapped}
            />
            <CrmTile
              crm={Crm.salesforce}
              enabled={organizationPipelineConfig?.isEnabled && organizationPipelineConfig?.integration === Crm.salesforce}
              loading={loading && syncingCrm === Crm.salesforce}
              disabledText={
                organizationPipelineConfig?.isEnabled &&
                organizationPipelineConfig?.integration &&
                organizationPipelineConfig?.integration === Crm.hubspot
                  ? "Hubspot integration is enabled"
                  : undefined
              }
              onSetupTapped={
                organizationPipelineConfig?.isEnabled &&
                organizationPipelineConfig?.integration &&
                organizationPipelineConfig?.integration === Crm.hubspot
                  ? undefined
                  : onSalesforceSetupTapped
              }
              onRemoveTapped={onRemoveTapped}
            />
          </>
        )}
      </MDBox>
    </Card>
  )
}

export default OrganizationCrmSyncCard
