// MUI
import { Icon, Paper } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import { HandshakeOutlined } from "@mui/icons-material"
import { Call } from "types/pipeline/call"
import CreateCallDrawer from "forge/pipeline/calls/components/CreateCallDrawer"
import { useState } from "react"
import { ForgeOpportunity } from "types/pipeline/opportunity"
import { ForgeAvatarGroup } from "forge/core/components/ForgeAvatar"
import MDSnackbar from "components/MDSnackbar"
import { ColorType, SnackbarProps } from "types/snackbar"
import { createPortal } from "react-dom"

function CallCard({ call, opportunity }: { call: Call; opportunity?: ForgeOpportunity }) {
  const [openCreateCallDrawer, setOpenCreateCallDrawer] = useState(false)
  const handleOpenCallDrawer = () => setOpenCreateCallDrawer(true)
  const handleCloseCallDrawer = () => setOpenCreateCallDrawer(false)

  // Snackbar
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    open: false,
    color: 'info',
    title: '',
    message: '',
  });

  const handleSnackbar = (color: ColorType, title: string, message: string) => {
    setSnackbar({
      open: true,
      color,
      title,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const renderSnackbar = createPortal(
    <MDSnackbar
      color={snackbar.color}
      icon="notifications"
      dateTime=""
      title={snackbar.title}
      content={snackbar.message}
      open={snackbar.open}
      onClose={handleCloseSnackbar}
      close={handleCloseSnackbar}
      bgWhite
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
        zIndex: 1500,
      }}
    />,
    document.body
  );

  return (
    <>
    {renderSnackbar}
    <MDBox mb={2}>
      <CreateCallDrawer openDrawer={openCreateCallDrawer} handleCloseDrawer={handleCloseCallDrawer} opportunity={opportunity} call={call} showSnackbar={handleSnackbar}/>
      <Paper elevation={2} style={{ borderRadius: 12 }}>
        <MDBox component="li" display="flex" justifyContent="space-between" alignItems="flex-start" bgColor={"grey-100"} borderRadius="lg" p={2}>
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox display="flex" justifyContent="space-between" alignItems="center" color="dark">
              <MDTypography
                variant="h6"
                fontWeight="small"
                color={call.contacts?.length > 0 ? "dark" : "text"}
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1
                }}
              >
                {call.name}
              </MDTypography>
              {call.opportunityId && <HandshakeOutlined />}
            </MDBox>
            {call.contacts?.length > 0 && (
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="caption">
                  With {call.contacts[0]?.name}
                  {call.contacts.length > 1 ? ` and ${call.contacts.length - 1} other${call.contacts.length - 1 == 1 ? "" : "s"}` : ""}
                </MDTypography>
              </MDBox>
            )}

            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              {call.contacts?.length > 0 && <ForgeAvatarGroup contacts={call.contacts} />}
              {call.contacts?.length == 0 && <div></div>}
              <MDButton
                variant="text"
                color={"info"}
                onClick={handleOpenCallDrawer}
                size="small"
                style={{ textTransform: "none", fontWeight: "400", padding: 0 }}
              >
                Review&nbsp;<Icon>arrow_forward</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Paper>
    </MDBox>
    </>
  )
}

// Setting default values for the props of CallCard
CallCard.defaultProps = {
  call: null
}

export default CallCard
